import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuth } from "../store/auth/selectors";

const PrivateRoute = ({ children }) => {
  const isAuth = useSelector(selectIsAuth);

  if (!isAuth) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/signin" />;
  }

  // authorized so return child components
  return children;
};

export default PrivateRoute;
