import React from 'react'

function JoinUs() {
  return (
    <div className='p-4 m-2 md:m-5 flex justify-center rounded-md  items-center flex-wrap bg-[#000000]'>
    <section className='my-8 p-5 md:w-4/6'>
      <h2 className='text-2xl sm:text-4xl font-bold mx-2.5 my-3 text-center p-5'>Engage and Be Heard</h2>
      <p className='my-3  text-[16px] sm:text-3xl  sm:font-semibold text-center indent-4'>Join the Conversation: Your Opinion Matters</p>
      <p className='my-3 text-[16px] sm:text-2xl  text-center sm:font-semibold indent-4 p-5'>PolicyWeb is more than a platform; it's a community.
      Here, your opinions shape the policy cards and fuel meaningful discussions.
      Your voice matters.</p>
      <p className='my-3 text-[16px] sm:text-2xl  text-center sm:font-semibold indent-4 p-5'>PolicyWeb serves as a vital link between you and your government representatives. By aligning
       public opinion with government actions, we ensure that policies
       reflect the true will of the people.</p>
    </section>
    </div>
  )
}

export default JoinUs