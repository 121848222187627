import { NavLink } from "react-router-dom";

const MenuItem = ({ link, title, linkClicked }) => (
  <li
  onClick={linkClicked}
    className={`hover:text-purple-500 mx-2 font-nunito sm:text-lg font-semibold
     `}
  >
    <NavLink to={link}>
      {title}
    </NavLink>
  </li>
);

export default MenuItem;
