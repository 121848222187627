import React from 'react'

function QueryByPrice({filters, onFilterChange}) {
  return (
    <div className='w-full md:w-1/4'>
    <select
    className='w-full rounded-md py-2 text-left
    text-black bg-white hover:bg-gray-100 font-bold'
      value={filters?.priceRange}
      onChange={onFilterChange}
    >
      <option value="">Price Range</option>  {/* Add Price Range */}
      <option value="1month">Last Month</option>
      <option value="3months">Last 3 Months</option>
      <option value="6months">Last 6 Months</option>
    </select>
    </div>
  )
}

export default QueryByPrice
