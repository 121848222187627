import React from 'react'
import { marketPlaceData } from '../../../mockups/Profile/profile_data'

function Analysis2() {
  return (
    <div className='flex flex-wrap'>
    {marketPlaceData.slice(4,7).map(({head, child1, child2}, idx) => {
      return ( 
      <div key={idx} className='m-4 text-center w-full'>
      <p className='bg-[#5B5B5B] rounded-md px-2 my-1'>{head}</p>
      <p>{child1}</p>
      <p>{child2}</p>
    </div>)
    })}
    </div>
  )
}

export default Analysis2