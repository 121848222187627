import React, { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Select from 'react-select';
import QueryByDate from './QueryByDate';
import QueryByPrice from './QueryByPrice';
import { queryCategories } from '../../../mockups/User/interests';


const EnhancedSearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [userCount, setUserCount] = useState(100);
  const [filters, setFilters] = useState({priceRange: ''});
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);




  const handleFilterChange = (e) => {
    const {value} = e.target
    setFilters({priceRange: value})
  }

const dateFormatChange = (dateString) => {
    let dateObj = new Date(dateString);
    // Convert to UTC, add microseconds (simulating them here)
    let isoDateString = new Date(dateObj.getTime() + 587642).toISOString();
    return isoDateString
}


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      priceRange:filters?.priceRange,
      query: selectedCategories.map(cat => cat.value),
      start_date: dateFormatChange(dateRange[0]?.startDate), //SET IN THE FORMAT AS BACKEND REQUIRES ITS DATE FORMAT
      end_date: dateFormatChange(dateRange[0]?.endDate),
      userCount
    })
    onSearch({
      priceRange:filters?.priceRange,
      query: selectedCategories.map(cat => cat.value),
      start_date: dateFormatChange(dateRange[0]?.startDate), //SET IN THE FORMAT AS BACKEND REQUIRES ITS DATE FORMAT
      end_date: dateFormatChange(dateRange[0]?.endDate),
      user_count: userCount
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">

    <div className='flex flex-col sm:flex-row sm:space-x-2 space-y-4 sm:space-y-0'>
      <QueryByPrice
        filters={filters}
        onFilterChange={handleFilterChange}
      />

      <QueryByDate
      dateRange={dateRange}
      handleChange={item => {
      // console.log(item.selection)
      setDateRange([item.selection])
      }}
      />

    </div>

      <Select
        isMulti
        options={queryCategories}
        value={selectedCategories}
        onChange={setSelectedCategories}
        placeholder="Select categories"
        className="w-full text-black"
      />



<div className="flex items-center bg-white text-black
 rounded-lg overflow-hidden shadow-md placeholder:text-xs md:placeholder:text-[16px]">
        <input
          type="text"
          placeholder="What type of data are you looking for?"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="flex-grow p-2 focus:outline-none"
        />
      </div>

      <div>
        <label htmlFor="userCount" className="block text-sm font-medium text-white">
          Number of Users
        </label>
        <input
          type="number"
          id="userCount"
          value={userCount}
          onChange={(e) => setUserCount(parseInt(e.target.value))}
          className="mt-1 block text-black w-full rounded-md border-gray-300
           shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200
           focus:ring-opacity-50"
        />
      </div>

      <div className='flex justify-end'>
      <button
      type='submit'
      className='w-full sm:w-[30%] my-4 sm:m-4 rounded-md py-2 px-6
      text-white bg-updatedPurple hover:bg-[#767cb9]'
      onClick={handleSubmit}>Submit</button>
      </div>
    </form>
  );
};

export default EnhancedSearchBar;
