import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "../store/auth/selectors";

const AuthenticatedRoute = ({ children }) => {
  const token = useSelector(getToken);

  if (token && token?.newUser === "true") {
    return <Navigate to="/dashboard" />;
  } else if (token && token?.newUser === "false") {
    return <Navigate to="/dashboard" />;
  }

  return children;

  
};

export default AuthenticatedRoute;
