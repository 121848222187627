import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { query_or_manage_dataSet } from '../../redux/Slices/sharedUseEffectSlice'

function MarketPlace() {
  const dispatch = useDispatch()
  return (
    <div className='mt-5 bg-[#000000] text-white p-6 rounded-xl'>
    <p className='font-bold text-2xl my-3'>Data MarketPlace</p>
    <p className='indent-8'>
    At OpenPolitica, we strongly believe that you should be in
     charge of your own data. Enter the data marketplace to see
     what data we have been collecting for you and learn how
     you can sell your data to our trusted partners
    </p>
<div className='pt-5'>
<Link
    to="/profile/market-place"
    className='block text-center bg-updatedPurple my-3 px-5
    py-2.5 rounded-lg hover:bg-[#767cb9]'>
    Click Here to View Your Data Profile
    </Link>

    <Link
    to="/profile/market-place/query"
    onClick={() => {
      dispatch(
        query_or_manage_dataSet({
        datasetQueryComponent : "query"
      })
      )
    }}
    className='block text-center bg-updatedPurple my-3 px-5
    py-2.5 rounded-lg hover:bg-[#767cb9]'>
    Click Here to Search Data Sets
    </Link>
</div>
    </div>
  )
}

export default MarketPlace
