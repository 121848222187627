import React, { useState } from 'react'
import { PasswordResetInput } from '../common/input'
import { interestOptions } from '../../mockups/User/interests'
import { useNavigate } from 'react-router-dom'

function Interests({feedbackToDisplay}) {

    const navigate = useNavigate()

    const [interest, setInterest] = useState("")
    const [totalButtonsToDisplay, setTotalButtonsToDisplay] = useState(5)

    const [userInterests, setUserInterests] = useState([])

    const onInterestChange = (e) => {
        setInterest(e.target.value)
    }

    const increaseInterests = () => {
        setTotalButtonsToDisplay(interestOptions.length)
    }

    const addInterest = (e) => {
        const value = e.target.id
        if (!userInterests.includes(value)) {
            setUserInterests((prevValue) => {
                return [...prevValue, value]
            })
        } else {
            setUserInterests(
                userInterests.filter(item => item !== value)
            )
        }
    }

    const submitInterests = () => {
        feedbackToDisplay(2)
        console.log(userInterests)
    }



    return (
        <div>
            <div className="min-h-screen flex items-center justify-center
            bg-backgroundColor py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl w-full bg-white p-5 rounded-md">
                    <div className="bg-[#353535] rounded-md p-5 py-12">

                        <h2 className="mt-6 text-center text-4xl font-extrabold text-white">
                            Interests
                        </h2>
                        <p className="text-white text-center mt-3 mb-5">
                            What type of data are you looking for?</p>

                        <div className='flex flex-col justify-center items-center '>
                            <div className='w-3/4 px-2'>
                                <PasswordResetInput
                                    handleChange={onInterestChange}
                                    value={interest}
                                    type="text"
                                />
                            </div>
                            <div className='w-3/4 flex flex-wrap m-5'>
                                {interestOptions
                                    .slice(0, totalButtonsToDisplay)
                                    .map((item, idx) => {
                                        return (
                                            <button
                                                onClick={addInterest}
                                                key={idx}
                                                id={item}
                                                className={`block m-2 rounded-md py-2
                                                px-4 text-white
                                                ${userInterests.includes(item)
                                                        ? "bg-[#1f4648]"
                                                        : "bg-greenOnDarkMode"
                                                    } `}
                                            >
                                                {item}
                                            </button>
                                        )
                                    })}

                                {totalButtonsToDisplay <= 5
                                    &&
                                    <button
                                        className='block m-3 rounded-md py-2 px-6
                                         text-white bg-updatedPurple hover:bg-[#767cb9]'
                                        onClick={increaseInterests}>Add More...</button>
                                }

                            </div>

                        </div>


                        <div className='flex justify-end'>
                            <button
                                className='block m-3 rounded-md py-2 px-6
                                text-white bg-updatedPurple hover:bg-[#767cb9]'
                                onClick={() => {
                                    navigate("/")
                                }}>Back</button>
                            <button
                                className='block m-3 rounded-md py-2 px-6
                                text-white bg-updatedPurple hover:bg-[#767cb9]'
                                onClick={submitInterests}>Submit</button>
                        </div>
                    </div>


                </div>


            </div>


        </div>
    )
}

export default Interests
