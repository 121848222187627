import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaCheck } from "react-icons/fa";
import { query_or_manage_dataSet } from '../../../redux/Slices/sharedUseEffectSlice';
import { useDispatch } from 'react-redux';

function SaleRequests() {
  const dispatch = useDispatch()
  const revenue = 30
  const [checked, setChecked] = useState(false)

  const toggleCheck = () => {
    setChecked(!checked)
  }

  return (
    <div className='mt-10'>
      <div className='bg-[#53556B] text-center p-4 rounded-md'>
        <p>You are making: </p>
        <p><span className='text-2xl'>${revenue}</span> per month</p>
      </div>

    <div 
    onClick={toggleCheck}
    className='flex justify-center bg-[#000000] py-3 my-2.5 cursor-pointer'>
      <p>I allow my data to be 
      <br />
      sold as part of data sets.</p>
      <div className='bg-white w-[30px]  mx-2 h-[30px] my-auto'>
        {checked &&  <FaCheck className='text-3xl text-black' />}
      </div>
      <div>

      </div>
    </div>

      <Link
    to="/profile/market-place/query"
    onClick={() => {
      dispatch(
        query_or_manage_dataSet({
        datasetQueryComponent : "query"
      })
      )
    }}
    className='block text-center bg-[#5B5B5B] mt-5 px-5
    py-2.5 rounded-lg hover:bg-[#413e3e]'>
    Click Here to Search Data Sets
    </Link>
    </div>
  )
}

export default SaleRequests