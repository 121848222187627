import React from 'react'
import Image1 from "../../assets/img/about2.jpg"
import Image2 from "../../assets/img/about3.jpg"

function Mission() {

    return (
        <div className='py-4 my-8 w-full'>

            <section className='flex flex-col  items-center justify-center p-4 flex-wrap text-center'>
                <div className='text-white p-3 w-full'>

                    <h1 className='text-2xl sm:text-3xl lg:text-5xl font-bold mx-2.5 my-3'>Empowering Democracy <br/> with Technology.</h1>
                    <p className=' indent-2 md:indent-8 text-[16px]  md:block sm:text-2xl'> OpenPolitica is at the forefront of integrating artificial intelligence<br className='hidden md:block'/> with democratic processes. Our mission is to bridge the gap between <br className='hidden md:block'/> citizens and 
                        policy-makers, ensuring every voice is heard and acted upon.
                    </p>
                </div>
                <div className='flex justify-center items-center xl:items-start  mx-4 w-[310px] md:w-[550px] '>
        <img className=' h-3/4 w-full rounded-lgrounded-lg'  src={Image1} alt='' />
    </div>
            </section>

            <section className='flex  flex-col  sm:flex-col-reverse items-center justify-center p-4 flex-wrap text-center'>

                <div className='text-white p-3 w-full  order-first sm:order-last'>
                    <h1 className='text-2xl sm:text-3xl lg:text-5xl font-bold mx-2.5 my-3'>Amplifying Voices<br/> through Innovation</h1>
                    <p className=' indent-4 md:indent-8 inline text-[16px] sm:text-2xl text-center'>At OpenPolitica, we believe in harnessing the power
                        of AI to <br className='hidden md:block'/>transform democracy. Our goal is to work collaboratively with
                        policymakers<br className='hidden md:block'/> and political organizations to amplify the voices of the people.
                        We strive to<br className='hidden md:block'/> make AI a catalyst for democratic change.</p>

                </div>
                <div className='flex justify-center items-center xl:items-start mx-4 w-[310px] md:w-[550px] bg-[#D9D9D9]order-last sm:order-first'>
                <img className='h-3/4  w-full rounded-lg' src={Image2} alt='' />
                </div>
            </section>

        </div>
    )
}

export default Mission
