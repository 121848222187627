/* eslint-disable no-param-reassign */
import {createSlice} from "@reduxjs/toolkit";

import {NAME} from "./constants";
import {
    initializeWeb3Auth,
    loginWeb3Auth,
    getUserAPI,
    updateUserDetails,
    updateImage,
    logout
} from "./thunk";

const initialState = {
    token: null,
    user: null,
    error: null,
    isAuth: false,
    isInitialized: false,
    isLoading: false,
    userUpdated: false
};

const authSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setAuth: (state, {payload}) => {
            state.user = payload.data;
            state.error = null;
            state.isAuth = true;
        },
        clearAuth: (state) => {
            state.user = undefined;
            state.token = null
            state.error = null;
            state.isAuth = false;
        },
        setError: (state, {payload}) => {
            state.error = payload;
        },
        stateReset: () => initialState,
        signupStart: (state) => {
            state.isLoading = true;
        },
        signupSuccess: (state, action) => {
            state.isLoading = false;
            state.user = action.payload;
            state.error = null;
        },
        signupFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        // SHOW WEB3AUTH LOG IN MODAL CASE
            .addCase(initializeWeb3Auth.pending, (state, action) => {
                state.isLoading = true;
                state.isInitialized = false
            })
            .addCase(initializeWeb3Auth.fulfilled, (state, action) => {
                state.isInitialized = true;
                state.isLoading = false;
            })
            .addCase(initializeWeb3Auth.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })

            // LOG USER IN CASE
            .addCase(loginWeb3Auth.pending, (state) => {
                state.isLoading = true;
                state.userUpdated = false
            })
            .addCase(loginWeb3Auth.fulfilled, (state, action) => {
                state.isAuth = true;
                state.token = action.payload;
                state.isLoading = false;
                state.userUpdated = true
            })
            .addCase(loginWeb3Auth.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })

            // GET USER DETAILS CASE
            .addCase(getUserAPI.pending, (state) => {
                state.isLoading = true;
               
            })
            .addCase(getUserAPI.fulfilled, (state, action) => {
                state.isAuth = true;
                state.user = action.payload;
                state.isLoading = false;
               
            })
            .addCase(getUserAPI.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })

            // UPDATE USER DETAILS CASE
            .addCase(updateUserDetails.pending, (state) => {
                state.isLoading = true;
                state.userUpdated = false
            })
            .addCase(updateUserDetails.fulfilled, (state, action) => {
                state.isAuth = true;
                state.isLoading = false;
                state.userUpdated = true
            })
            .addCase(updateUserDetails.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })

            // UPDATE USER IMAGE CASE
            .addCase(updateImage.pending, (state) => {
                state.isLoading = true;
                state.userUpdated = false
            })
            .addCase(updateImage.fulfilled, (state, action) => {
                state.isAuth = true;
                state.isLoading = false;
                state.userUpdated = true
            })
            .addCase(updateImage.rejected, (state, action) => {
                state.error = action.payload;
                state.isLoading = false;
            })

            

            // LOG USER OUT OF WEB3AUTH
            .addCase(logout.fulfilled, (state) => {
                state.isAuth = false;
                state.user = null;
                state.token = null
            });
    }
});
export const {
    setAuth,
    clearAuth,
    setError,
    signupStart,
    signupSuccess,
    signupFailure
} = authSlice.actions;

export default authSlice;
