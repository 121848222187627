import React from 'react'

function PayCard({expireDate, cardNumber,isDefault, onChange }) {
  return (
    <>
<div className='bg-[#909090] h-[40px] w-2/12 pr-3 mt-1 rounded-xl' />
<div className='w-9/12 px-4 flex flex-col justify-between'>
    <div>
    <p className='font-bold'>{cardNumber}</p>
    <p className='text-[14px]'>{expireDate}</p>
    </div>

    <div className='flex'>
    <p className='text-[14px]'>{isDefault && "Default"}</p>
    <div className={`space-x-3 flex ${isDefault ? "ml-7" : "ml-[76px]" }`}>
    <p className='text-[14px] text-updatedPurple cursor-pointer'>Edit</p>
    <p className='text-[14px] text-[#FFAEAE] cursor-pointer'>Delete</p>
    </div>
    </div>
</div>

<div className='w-1/12'>
    <input
    name='default'
    onChange={onChange}
    className='bg-transparent rounded-full h-4 border-white border-2'
    type='radio'
    />
</div>
    </>
  )
}

export default PayCard
