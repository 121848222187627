import { createSlice } from "@reduxjs/toolkit";

const user =[]


const userSlice = createSlice({
    name:"users",
    initialState: user,
    reducers:{}
})

// export const {

// } = userSlice.actions

export default userSlice.reducer
