import React, { useState } from 'react'
import { cardDetails } from '../../mockups/Profile/payCard'
import PayCard from './PayCard'
import { FaPlus } from "react-icons/fa6";
import { resetActions } from '../common/show_and_hide';

function Payments({addNewPaymentBox, desktopAddNewPaymentBox}) {

    const [cards, setCards] = useState(Array.from({ length: cardDetails.length }, () => false));
    const handleChange = (e, index) => {
        resetActions(index, cards, setCards)
    }

    const openBox = () => {
        addNewPaymentBox()
    }


  return (
    <div className='bg-nightModeDark p-4 lg:px-0 rounded-xl my-6'>

<p className='mt-4 font-semibold text-2xl lg:text-lg'>Payment Methods</p>


    {cardDetails && cardDetails.map(({expires,cardType, isDefault }, idx) => {
        return (
            <div
            key={idx}
            className={`${cards[idx] &&  "bg-[#494848]" }  w-full h-[120px]
            flex justify-betweenb p-2.5 rounded-xl my-4`}>
            <PayCard
            expireDate={expires}
            cardNumber={cardType}
            isDefault={isDefault}
            onChange={(e) => {
                handleChange(e, idx)
            }}

            />
            </div>
        )
    })}

    <div
    className='hidden lg:flex mt-5 mb-3 cursor-pointer'
    onClick={() => {
        desktopAddNewPaymentBox()
    }}>
        <FaPlus className='m-1' /><button className='ml-2'>Add new payment method</button>
    </div>

    <div
    className='flex lg:hidden mt-5 mb-3 cursor-pointer'
    onClick={openBox}>
        <FaPlus className='m-1' /><button className='ml-2'>Add new payment method</button>
    </div>


    </div>
  )
}

export default Payments
