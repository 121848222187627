import React from 'react'
import { IoPeopleOutline } from "react-icons/io5";
import { ChatIconBot } from '../common/svg-icons/svgs';
import { LuScrollText } from "react-icons/lu";
import backgroundImage from "../../assets/img/pattern-bg2.png"

function BriefDesc() {
  return (
    <div
    className="w-full sm:w-[95%] lg:ml-4 py-3 bg-[#211E1E] rounded-l-lg my-8 "
    style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className='flex justify-center flex-wrap pt-4 pl-6 pr-4'>
    <h3 className='w-full sm:w-[83%] text-2xl lg:text-3xl font-semibold text-white'>
    Our policy-focused AI,
    </h3>
    <h3 className='w-full sm:w-[83%] text-2xl lg:text-3xl font-semibold text-white'>
    PolicyWeb is designed to <br className='hidden sm:block'/> tailor to your specific needs.</h3>
    </div>
    <div className='w-full flex justify-end p-2.5 pb-8 lg:pb-[130px]'>
        <p className='w-2/4 sm:w-[75%] text-white text-sm sm:text-lg text-center'>
        No matter your needs, our AI <br className='hidden sm:block'/> chatbot is equipped
        with the tools to <br className='hidden sm:block'/> put your priorities in the spotlight.</p>
    </div>

    {/* ICONS DESCRIPTIONS */}
    <div className='my-7 lg:mx-[120px] lg:flex lg:justify-center'>

    <div className='flex justify-center flex-wrap lg:flex-nowrap'>
    <div className='lg:mt-[-20px]'>
    <IoPeopleOutline className='text-white text-6xl'/>
    <p className='w-full font-bold text-center text-white text-xl'>You</p>
    </div>
    <div className='flex lg:block lg:px-4'>
    <p className='lg:mt-[-130px] w-2/4 lg:w-3/4 py-4 px-6 text-sm text-white'>
    PolicyWeb actively collects data and analyzes the way you
    interact with the policies that matter to you most.</p>
    <div className="my-3 mr-2 w-[4px] h-[130px] lg:w-[100%] lg:h-[4px] bg-white"></div>
    </div>
    </div>


    <div className='flex justify-center flex-wrap lg:flex-nowrap'>
    <div className='lg:mt-[-50px]'>
    <div className='ml-3 lg:ml-0'><ChatIconBot/></div>
    <p className='w-full font-bold text-center text-white text-xl'>PolicyWeb</p>
    </div>
    <div className='flex lg:block justify-end'>
    <div className="my-3 ml-5 lg:ml-0 w-[4px] h-[130px] lg:w-[90%] lg:h-[4px] bg-white"></div>
    <p className='w-2/4 lg:w-3/4 p-4 text-sm text-white lg:ml-5'>
    Your concerns become our primary focus. Our goal is to help you
     voice your needs, bringing you one step closer to policymakers.</p>
    </div>
    </div>



    <div className='flex justify-center flex-wrap py-3'>
    <div className='lg:mt-[-35px]'>
    <LuScrollText className='ml-8 lg:ml-0 text-white text-6xl'/>
    <p className='w-full font-bold text-center text-white text-xl'>Policymakers</p>
    </div>
    </div>

    </div>

     {/* TEXT DESCRIPTIONS */}

     <div className='flex justify-center flex-wrap pt-4 px-4'>
     <p className='sm:w-[83%] py-4 px-2 text-sm sm:text-lg text-white'>
     At OpenPolitica, every conversation is a step toward action, driving us closer
     <br className='hidden sm:block'/> to solutions that reflect your concerns and aspirations.</p>
    <h3 className='w-full px-2 sm:w-[83%] text-2xl lg:text-3xl font-semibold text-white'>
    Your Concerns, Our Action.
    </h3>
    <h3 className='w-full px-2 sm:w-[83%] text-2xl lg:text-3xl font-semibold text-white'>
    Let's Shape the Future Together.</h3>
    </div>



</div>
  )
}

export default BriefDesc
