export const interestOptions = ["Healthcare", "Urban Development", "Economy", "Nutrition",
"Physical Wellness", "Education", "Mental Wellness", "Income", "Sustainability", "Rehabilitation"]


export const queryCategories = [
    { value: 'health', label: 'Healthcare' },
    { value: 'urban_development', label: 'Urban Development' },
    { value: 'economy', label: 'Economy' },
    { value: 'nutrition', label: 'Nutrition' },
    { value: 'physical_wellness', label: 'Physical Wellness' },
    { value: 'mental_wellness', label: 'Mental Wellness' },
    { value: 'education', label: 'Education' },
    { value: 'income', label: 'Income' },
    { value: 'sustainability', label: 'Sustainability' },
    { value: 'rehabilitation', label: 'Rehabilitation' },
]
