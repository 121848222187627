import React from "react";
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerItems = [
    { link: "/", title: "Home" },
    { link: "/dashboard", title: "Dashboard" },
    { link: "/chat", title: "AI Journal" },
    { link: "/about", title: "About Us" },
  ];
  const companyItems = [
    { link: "/about#mission", title: "Mission" },
    { link: "/sponsors", title: "Sponsors" },
    { link: "/partners", title: "Partners" },
  ];
  const socialItems = [
    { link: "/intagram", title: "Instagram" },
    { link: "https://www.linkedin.com/company/102708055/admin/feed/posts/", title: "LinkedIn" },
    { link: "https://github.com/OpenPoliticaOrg", title: "Github" },
  ];
  const contactItems = [
  "OpenPolitica@gmail.com",
  "312-xxx-xxx" ,
  "1234 W 11th Street",
   "Denver, CO 12345"
  ];
  return (
    <footer className="bg-[#3C6E71] pt-8 sm:pb-8 pb-[110px] pl-3 lg:pl-[68px] mt-16 
    flex justify-start sm:justify-center flex-col md:flex-row space-y-5 sm:space-y-0">
      <p className="mx-10 mb-4 text-white text-center block md:hidden  font-bold text-lg ">
          Join us in changing the <br className='hidden md:block'/>world of policies!
            <br className=''/>Join OpenPolitica!
        </p>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0">

      <div className="flex-col w-full sm:w-auto ">
      <p className=" mx-1 lg:mx-[53px] text-[#353535] font-bold text-lg sm:text-xl mb-1">
          OpenPolitica
        </p>
        <ul className="flex-col items-center lg:mx-12 text-white">
          {footerItems.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </ul>
      </div>
      <div className="flex-col w-full sm:w-auto ">
      <p className=" mx-1 lg:mx-[53px] text-[#353535] font-bold text-lg sm:text-xl  mb-1">Company</p>
      <ul className="flex-col items-center lg:mx-12 text-white">
          {companyItems.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </ul>
      </div>
      <div className="flex-col w-full sm:w-auto">
      <p className=" mx-1 lg:mx-[53px] text-[#353535] font-bold text-lg sm:text-xl mb-1">Social</p>
      <ul className="flex-col items-center lg:mx-12 text-white">
          {socialItems.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </ul>
      </div>
      <div className="flex-col w-full sm:w-auto">
        <p className="mx-1  lg:mx-[53px] text-[#353535] font-bold text-lg sm:text-xl  mb-1">
          Contact Us
        </p>
        <ul className="flex-col items-center lg:mx-12 text-white">
          {contactItems.map((item, index) => (
            <li
            key={index}
            className={`mx-2 font-nunito sm:text-lg font-semibold`}>
            {item}
            </li>
          ))}

        </ul>
      </div>
      </div>
      <div className="flex-col w-full sm:w-auto items-center justify-center">
        <p className="mx-10 text-[#353535] text-center hidden md:block  font-bold text-lg">
          Join us in changing the world of policies! 
           <br className='hidden md:block'/>
           Join OpenPolitica!
        </p>
        <Link to="/" className="mt-10 justify-center flex items-center">
          <img src="../.././assets/img/logo.svg" alt="logo" />
          <p className="text-white text-2xl font-bold mx-3">OpenPolitica</p>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
