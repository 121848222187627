import React from 'react'
import StatsBasics from './StatsBasics'
import StatsPsychometrics from './StatsPsychometrics'


function StatsMain() {
  return (
    <div className='bg-[#202020] text-white p-3 m-3 sm:mx-0 rounded-md'>
    <StatsBasics />

    <p className='mt-5 font-bold text-2xl'>Psychometrics</p>
    <StatsPsychometrics/>
    </div>
  )
}

export default StatsMain
