import React, { useEffect } from 'react';
import L from 'leaflet';
import { statesData } from './us-states';
import 'leaflet/dist/leaflet.css';
import './openMap.css';
import { useSelector, useDispatch } from 'react-redux';
import { searchedLocation, searched_LAT_LONG } from '../../../redux/Slices/sharedUseEffectSlice';


const OpenPolMapComponent = () => {
    const dispatch = useDispatch()
    // See if the user lat and long returns a result
    const bounds = useSelector(searched_LAT_LONG)

  useEffect(() => {
    let map;
    let geojson;

// Additional functions for map interactivity and data processing
function getColor(d) {
    return d > 1000 ? '#d95f0e' :
           d > 500  ? '#BD0026' :
           d > 200  ? '#E31A1C' :
           d > 100  ? '#fec44f' :
           d > 50   ? '#FD8D3C' :
           d > 20   ? '#FEB24C' :
           d > 10   ? '#fff7bc' :
                      '#FFEDA0';
}

function style(feature) {
    return {
        fillColor: getColor(feature.properties.density),
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
    };
}

function highlightFeature(e) {
    let layer = e.target;

    layer.setStyle({
        weight: 5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
    }
}


function resetHighlight(e) {
    geojson.resetStyle(e.target);
}

function zoomToFeature(e) {
    map.fitBounds(e.target.getBounds());
    const userClickedLoc = e.target?.feature?.properties?.name
    dispatch(
        searchedLocation({
            location: userClickedLoc
        })
    )
}

function onEachFeature(feature, layer) {
    layer.on({
        mouseover: highlightFeature,
        mouseout: resetHighlight,
        click: zoomToFeature
    });
}

    let cord, zoomLevel
    if(bounds !== ""){
        cord = bounds
        zoomLevel = 6
    }else{
        cord = [37.8, -96]
        zoomLevel = 4
    }

   map = L.map('map').setView(cord, zoomLevel);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
    }).addTo(map);

    geojson = L.geoJson(statesData, {
        style,
        onEachFeature,
    }).addTo(map);

    // Cleanup function
    return () => {
      map.remove();
    };

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]); // Empty dependency array ensures this runs once on mount

  // ... (other functions like getColor, style, highlightFeature, etc.)



  return (
    <div id="map"></div>
  );


};

export default OpenPolMapComponent;
