import React from 'react'
import { RiDashboardLine } from "react-icons/ri";
import { BiConversation, BiAddToQueue } from "react-icons/bi";
import {  PiHandsPrayingDuotone } from "react-icons/pi";

const Walkthrough = () =>{
  return (
    <div className=' flex justify-center items-center flex-wrap p-4 my-8 w-full'>
    <h2 className='text-2xl sm:text-3xl lg:text-5xl font-bold mx-2.5 my-3 text-center'>
    Transforms Your Voice into Action</h2>
    <p className='flex items-center justify-center indent-6 lg:indent-8 text-[16px] lg:w-5/6 sm:text-2xl m-4'>
       We are a team of driven individuals from all around the world working on one mission: to empower the future of policymaking 
       through modern technology and user friendly design!
    </p>

  <div className='relaive flex flex-wrap justify-center m-4 items-center w-full'>
    <div className='grid grid-rows-4 lg:grid-rows-2 grid-flow-row lg:grid-flow-col gap-4'>
    {/* DESC 1 */}
    <div className='bg-greenOnDarkMode sm:bg-[3C6E71] rounded-md
                        flex flex-wrap w-full md:w-[450px] m-2 p-2.5 sm:space-y-4'>
            <div className={`my-auto w-[20%]`}>
           <BiConversation className='text-white text-[40px] md:text-[56px] mx-auto' />
            </div>
            <div className='w-[80%] text-left px-2 mt-4 sm:mt-0'>
            <p className='sm:text-xl md:text-2xl font-bold my-1'>Start a Conversation</p>
            <p className='text-sm md:text-xl'>Share your thoughts and concerns with our AI chatbot.</p>
            </div>
        </div>


    {/* DESC 2 */}
    <div className='bg-greenOnDarkMode sm:bg-[3C6E71] rounded-md
                        flex flex-wrap w-full md:w-[450px] m-2 p-2.5 sm:space-y-4'>
            <div className={`my-auto w-[20%]`}>
            <RiDashboardLine  className='text-5xl border-none shadow-2xl
                                                     mx-auto'/>
            </div>
            <div className='w-[80%] text-left px-2 mt-4 sm:mt-0'>
            <p className='sm:text-xl md:text-2xl font-bold my-1'>Survey Creation</p>
            <p className='text-sm md:text-xl'>Based on your conversation, a custom survey is generated.</p>
            </div>
        </div>

            {/* DESC 3 */}
    <div className='bg-greenOnDarkMode sm:bg-[3C6E71] rounded-md
                        flex flex-wrap w-full md:w-[450px] m-2 p-2.5 sm:space-y-4'>
            <div className={`my-auto w-[20%]`}>
            <BiAddToQueue  className='text-5xl md:text-6xl border-none shadow-2xl
                                                     mx-auto'/>
            </div>
            <div className='w-[80%] text-left px-2 '>
            <p className='sm:text-xl md:text-2xl font-bold my-1'>Policy Card Development:</p>
            <p className='text-sm md:text-xl'>Each main concern or outcome leads to the creation of a policy card.</p>
            </div>
        </div>

            {/* DESC 4 */}
    <div className='bg-greenOnDarkMode sm:bg-[3C6E71] rounded-md
                        flex flex-wrap w-full md:w-[450px] m-2 p-2.5 sm:space-y-4'>
            <div className={`my-auto w-[20%]`}>
               <PiHandsPrayingDuotone className='text-5xl md:text-6xl border-none shadow-2xl
                                                     mx-auto' />
            </div>
            <div className='w-[80%] text-left px-2 mt-4 sm:mt-0'>
            <p className='sm:text-xl md:text-2xl font-bold my-1'>Community Engagement.</p>
            <p className='text-sm md:text-xl'>Join forums, discuss, and view data visualizations.</p>
            </div>
        </div>

        </div>
  </div>
    
        </div>
  )
}

export default Walkthrough
