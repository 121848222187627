import React from "react";
import DatasetCard from "../QueryComponent/DataSetCard";
import { IoMdArrowDropdown } from "react-icons/io";

const PurchaseHistory = ({ pastData, handleRenewal }) => (
  <div className="mt-8 px-3 lg:px-10 relative h-[450px]
  overflow-y-scroll scroll-bar-in-dark">
 <p className='sticky top-0 w-full bg-updatedPurple text-2xl md:text-4xl text-center
 italic pb-4'>Past Purchases</p>
  <div>
  {pastData && pastData.map((dataset, idx) => (
     <DatasetCard key={idx}
      {...dataset}
      actionName="Renew"
      onPurchase={handleRenewal} />
   ))}
  </div>

  {pastData.length > 1
  &&
  <p className='pt-2 text-center font-semibold text-sm sticky bottom-0 w-full bg-updatedPurple '>
    Scroll down to see more
    <IoMdArrowDropdown className='text-xl mx-auto text-white' />
    </p>
    }
    
 </div>
  );

  export default PurchaseHistory
