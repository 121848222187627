import React from 'react'

function Componentsloader() {
  return (
    <div className='flex item-center justify-center h-full w-full'>
    <div
    className="my-4 animate-spin rounded-full h-16 w-16
    border-t-4 border-[#3C6E71]"></div>
    </div>
  )
}

export default Componentsloader
