import React from 'react'
import { PasswordResetInput } from '../common/input'
import { GoArrowLeft } from "react-icons/go";

function AddNewPaymentMethod({addNewPaymentBox, closePopUp}) {

    const handleSubmit = () => {


      closePopUp()
    }


  return (
    <div className='bg-nightModeDark lg:bg-[#F3EDED] lg:text-black p-4 rounded-xl my-6'>

    <button
    className='lg:hidden'
    onClick={() => {
        addNewPaymentBox()
    }}
    ><GoArrowLeft className='inline mb-0.5 ' /> Go Back</button>

    <p className='lg:px-12 mt-6 font-semibold text-2xl'>
    Add a new payment method
    </p>

    <div className='flex justify-between mt-6 px-3'>
  <div className='space-x-3 flex'>
    <div className='flex space-x-2'>
        <p>Credit</p>
        <input
  className='bg-transparent rounded-full h-4 border-white lg:border-black border-2 mt-1'
  type="radio"  name="cardType" value="Credit" />
    </div>

    <div className='flex space-x-2'>
    <p>Debit</p>
    <input
  className='bg-transparent rounded-full h-4 border-white lg:border-black border-2 mt-1'
  type="radio" name="cardType" value="Debit" />
    </div>

  </div>

  <div>
    Cards
  </div>

    </div>

<form>
    <div className='my-4 px-3 space-y-4'>
    <PasswordResetInput
            // handleChange={handleInput}
            // value={inputValue.first_name}
            name="cardholder"
            label="Name of Cardholder"
            type="text"
            placeholder=""
          />

<PasswordResetInput
            // handleChange={handleInput}
            // value={inputValue.first_name}
            name="card_number"
            label="Card Number"
            type="text"
            placeholder=""
          />

    <div className='flex justify-between space-x-8'>
    <PasswordResetInput
            // handleChange={handleInput}
            // value={inputValue.first_name}
            name="expiresAt"
            label="MM/YY"
            type="text"
            placeholder=""
          />

<PasswordResetInput
            // handleChange={handleInput}
            // value={inputValue.first_name}
            name="cvv"
            label="CVV"
            type="text"
            placeholder=""
          />
    </div>
    </div>
    <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              className='w-2/4 text-center bg-updatedPurple my-3 lg:px-5
               p-2.5 rounded-lg hover:bg-[#767cb9]'>
              Save Card
            </button>
            </div>
    </form>

    </div>
  )
}

export default AddNewPaymentMethod
