import React from 'react'

function DataCard() {
  return (
    <div className='bg-white p-4 py-5 text-black rounded-md'>
    <div className='flex justify-between'>
                <p className='font-bold md:text-2xl w-[70%] mr-3'>Health Insurance</p>
                <p className='font-bold text-sm italic w-auto'>
                    <span className='md:text-2xl not-italic text-[16px]'>$350 </span> 
                    <br />
                    from 4/30
                    <br />
                     to 4/30</p>
            </div>
    <p className='mb-2 italic font-semibold text-xs md:text-[16px]'>Extracted from: 742 accounts</p>
    <div className='bg-[#353535] text-white p-2.5 md:p-4 text-justify rounded-md'>
      <p className='indent-3'>Based off your search, we recommend this dataset
       for your purposes. Our PolicyWeb has carefully curated
        this set of data through an accumulation of our most
         frequented users. This ensures that both you and our
          trusted partners are happy with the information we
          are providing, Things you may see include, most relevant
           discussion threads, data relevant to your needs, and graphs
           that analyze the use of the website by our users.</p>
    </div>

    </div>
  )
}

export default DataCard
