import React from "react";
import Form from "../components/SignIn/Form";
import Footer from "../layout/Footer";


const SignIn = () => {

  return (
    <div>
    <div className=" grid md:grid-cols-2 grid-flow-row gap-4 my-10 mx-5 lg:mx-60 bg-white rounded-lg">
      <div>
        <div className="flex flex-col justify-center px-8 md:pl-16 md:py-36">
          <div className="text-center">
            <h1 className="text-3xl md:text-5xl font-bold mt-4">
              Your Journey
            </h1>
            <span className="text-3xl md:text-5xl font-bold">Starts Here</span>
          </div>

          <div className="md:pr-5">
            <p className="mt-5 md:ml-5 text-base md:text-lg lg:indent-8">
              At OpenPolitica, we seek to bridge the gap between
              policymakers and the public, leveraging artificial intelligence
              and data analysis to identify user needs, generate surveys, and
              assess the impact of policies.
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center items-center relative">
        <div className="mb-5 md:mt-9 md:mr-14 bg-[#3C6E71]
         border-white rounded-lg  flex items-center relative">
          <div className="relative z-10">
            <div className="p-8 pt:8 md:pt-16 text-white">
              <h1 className="text-2xl md:text-4xl text-center font-bold mb-2 md:mb-5">
                Welcome Back!
              </h1>
              <p className="text-center text-sm md:text-base">
                Please Log in to Continue.
              </p>
            </div>

            <Form />
            <div className="bg-[#72A0A3] text-center  justify-center items-center rounded-lg gap-4 ml-7 mr-7 mt-2  lg:mt-4 mb-10">
              <p className="italic  text-white p-3 lg:p-8 font-medium">
                Fun Fact: The more you use our platform, the more we can tailor your experience towards you!
              </p>
            </div>
          </div>
       <div className="absolute bg-[#335C5F] h-[80px] w-[70%] lg:w-[60%] rounded-r-3xl left-0 bottom-[125px] lg:bottom-[150px]">
        {/* the color palettes */}
        </div>
        <div className="absolute bg-[#335C5F] h-[80px] w-[70%] lg:w-[60%] rounded-r-3xl left-0 bottom-[20px] lg:bottom-[20px]"> 
         {/* the color palettes */}
        </div>
        </div>


      </div>


    </div>
    <Footer />
    </div>
  );
};

export default SignIn;
