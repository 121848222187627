import React from 'react';
import { readableDate } from '../../common/set_date';

const PolicyCardContent = ({currentMode, category, content,
  policy_makers, regional_info, effective_date, showAll, cardInProfileUrl}) => {

  const date_object = readableDate(effective_date)

    return (
        <div className={` ${!currentMode ? `bg-chatTipBackground text-black`
                            :
                              `bg-nightModeDark text-white `}
                        rounded-t-lg px-2 md:px-7 py-4 space-y-2.5
                         border-transparent inter-justify
                         ${cardInProfileUrl ? "text-xs" : "text-sm sm:text-[16px]" }
                         `}>
                        <h1
                        className={`
                        ${cardInProfileUrl ? "" : "text-xl md:text-3xl" }
                         font-bold `}>{category} </h1>
                        <p><span className=''>
                        Policy Makers:</span> {policy_makers} </p>

                        <p><span className=''>
                        Region:</span>  {regional_info}</p>

                        <p><span className=''>
                        Effective From:</span> <b>{date_object}</b></p>

                        <p className={`inter-justify
                        ${!currentMode ? `bg-updatedPurple` : `bg-greenOnDarkMode`}
                         text-white p-4 rounded-md`}>
                          {showAll ? content : content && content.substring(0, 200)}
                        </p>
                    </div>
    );
}

export default PolicyCardContent;
