import React from 'react'
import { MdModeEdit } from "react-icons/md";
import { useSelector } from 'react-redux';
import { userInfo } from '../../redux/Selectors/selectors';


function UserAvatar({settingsPage, handleClick}) {

    const user = useSelector(userInfo)



  return (

    <div className='z-10 relative top-0 md:absolute md:-top-[100px] md:w-[160px]'
    >
    <div className='relative w-[100px] h-[100px] md:h-[150px] md:w-[150px] '>
    <img
    src={user && user?.profileImage}
    alt="User Avatar"
    className={`w-full h-full
    rounded-full p-3`}
     />

     {settingsPage &&
        <>
        <div
        onClick={handleClick}
        className='top-0 absolute bg-black opacity-60
        flex justify-center items-center z-[3] w-[100px] h-[100px]
     md:h-[150px] md:w-[150px] rounded-full p-3'
     />

     <MdModeEdit
     onClick={handleClick}
     className='absolute top-[35px] left-[35px]
     md:top-[60px] md:left-[60px]  text-4xl sm:text-4xl z-[4] text-white' />
        </>
    }
    </div>

    </div>
  )
}

export default UserAvatar
