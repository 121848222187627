import React, { useEffect, useState } from 'react'
import { PasswordResetInput } from '../common/input'
import { useDispatch, useSelector } from 'react-redux'
import { userInfo } from '../../redux/Selectors/selectors'
import Payments from './Payments'
import AddNewPaymentMethod from './AddNewPaymentMethod'
import { getUserAPI, updateUserDetails } from '../../store/auth/thunk'
import { getNameList } from 'country-list'



function ProfileSettings() {
  const dispatch = useDispatch()
  const countryList = getNameList();
  const user = useSelector(userInfo)
  const [inputValue, setInputValue] = useState(user || {
    name: "",
    email:"",
    wallet_address:"",
    phone_number:"",
    country:"United states of america",
    city:"",
    state:"",
  })
  const [bio, setBio] = useState(user?.bio)
  const [refresh, setRefresh] = useState(false)
  const [popUp, setPopUp] = useState(false)


  const togglePopUp = () => {
    setPopUp(!popUp)
  }

  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);   
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    setInputValue((prevValue) => ({...prevValue, [name] : value}))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    
    const body = {
      name: inputValue.name,
      email: inputValue.email,
      wallet_address: inputValue.wallet_address,
      phone_number: inputValue.phone_number,
      country: inputValue.country,
      city: inputValue.city,
      state: inputValue.state,
    }
    // console.log

    // Update user details
    dispatch(
      updateUserDetails({
        user_id: user.id,
        ...body,
        bio
      })
    )

    setRefresh(!refresh)

  }

useEffect(() => {
  dispatch(getUserAPI())

  // eslint-disable-next-line 
},[refresh])



  return (
    <div className='bg-nightModeDark p-4 rounded-xl relative'>
      <p className='lg:px-12 px-3 mt-4 font-semibold text-2xl'>Profile Settings</p>

      <div className='m-3 sm:m-5 flex flex-col lg:flex-row w-full'>

      {inputValue && 
      (
        <div className='w-[92%] lg:max-w-2/4 space-y-4 lg:m-4 lg:px-4'>
          <PasswordResetInput
            handleChange={handleInput}
            value={inputValue.name}
            name="name"
            label="Name"
            type="text"
            placeholder=""
          />
          <PasswordResetInput
            handleChange={handleInput}
            value={inputValue.email}
            name="email"
            label="Email"
            type="text"
            placeholder=""
          />
          <PasswordResetInput
            handleChange={handleInput}
            value={inputValue.wallet_address}
            name="wallet_address"
            label="Wallet Address"
            type="text"
            placeholder=""
          />
          <PasswordResetInput
            handleChange={handleInput}
            value={inputValue.phone_number}
            name="phone_number"
            label="Phone Number"
            type="text"
            placeholder=""
          />

          <div className='flex space-x-2'>

          <div className='w-full px-4 outline-none
      border-white text-black focus:border-white
      placeholder:text-gray-600 placeholder:text-sm
      border-1 rounded-lg'>
       <p className='text-white mb-2'>Country</p>
            <select
              id="country"
              name="country"
              className="py-2.5 border rounded-md w-full h-[46px]"
              onChange={handleInput}
            >
              {/* Map the countryList object to create options */}
              <option value={inputValue.country}>
              {capitalizeFirstLetter(inputValue.country  || "United States of America")}
              </option>
              {Object.keys(countryList).map((countryName) => (
                <option key={countryName} value={countryName}>
                  {capitalizeFirstLetter(countryName)}
                </option>
              ))}
              </select>
          </div>

            <PasswordResetInput
              handleChange={handleInput}
              value={inputValue.city}
              name="city"
              label="City"
              type="text"
              placeholder=""
            />

            <PasswordResetInput
              handleChange={handleInput}
              value={inputValue.state}
              name="state"
              label="State"
              type="text"
              placeholder=""
            />

          </div>

        <div className='my-4 hidden lg:block'>
        <button
              onClick={handleSubmit}
              className='w-2/4 text-center bg-updatedPurple my-5 lg:px-5
               p-2.5 rounded-lg hover:bg-[#767cb9]'>
              Apply Changes
            </button>
        </div>
        </div>
      )}




        <div className='w-full lg:w-2/4 space-y-4 my-4 lg:m-4 lg:px-8'>
            <div>
            <p>Brief Description:</p>
          <textarea
          value={bio}
          onChange={(e) => {
            setBio(e.target.value)
          }}
          className='text-black p-4 my-2.5 min-w-[300px] lg:min-w-[420px] min-h-[140px] rounded-xl'>
          </textarea>
            </div>

            <div className='my-4 lg:hidden'>
        <button
              onClick={handleSubmit}
              className='w-2/4 text-center bg-updatedPurple my-5 lg:px-5
               p-2.5 rounded-lg hover:bg-[#767cb9]'>
              Apply Changes
            </button>
        </div>

           <div className='hidden lg:block'>
           <Payments
            desktopAddNewPaymentBox={togglePopUp}
            />
           </div>
        </div>

      </div>



{/* POP-UP */}
          {popUp &&
            <div
            onClick={togglePopUp}
            className='hidden lg:flex justify-center items-center
            absolute top-0 h-full w-full z-[2] bg-black bg-opacity-60'>

      <div onClick={(e) => e.stopPropagation()}>
              <AddNewPaymentMethod closePopUp={togglePopUp} />
            </div>

            </div>
          }

    </div>
  )
}

export default ProfileSettings
