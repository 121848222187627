import React, { useState } from 'react'
import Footer from '../layout/Footer'
import { PasswordResetInput } from '../components/common/input'
// import { useParams } from 'react-router-dom'
import isValidPassword from '../components/common/passwordValidator'
import PasswordFeedback from '../components/common/passwordFeedback'
import { IoMdEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";

function ResetPassword() {
    // let { tokenID } = useParams()
    const pwdValidator = `Password must be atleast 10 characters including an
                          uppercase,lowercase and a special character`
    const [inputPassword, setInputPassword] = useState({
        password: "",
        confirm_password: ""
    })
    const [state, setState] = useState(0)
    const [validating, setValidating] = useState(pwdValidator)
    // console.log(state)


    const handleChange = (e) => {
        const { name, value } = e.target
        const result = isValidPassword(value)
        if (result === "Too Weak") {
            setState(1)
        } else if (result === "Fair") {
            setState(2)
        } else if (result === true) {
            setState(3)
        }

        if (!value) {
            setState(0)
            setValidating(pwdValidator)
        }

        setInputPassword((prev) => {
            return {
                ...prev,
                [name]: value

            }
        })
    }

    const [showTypedPwd, setShowTypedPwd] = useState(false)

const togglePwd = () => {
    setShowTypedPwd(!showTypedPwd)
}

    const resetPassword = (e) => {
        e.preventDefault()
        // If fields are not empty
        if (inputPassword.oldpassword && inputPassword.password && inputPassword.password) {
            // Check if both passwords match
            if (inputPassword.password !== inputPassword.confirm_password) {
                setValidating("Passwords do not match.")
            } else {
                const passwordX = isValidPassword(inputPassword.password)
                const passwordY = isValidPassword(inputPassword.confirm_password)

                // Check if both passwords pass our validation check
                if (passwordX && passwordY) {
                    console.log("Good to go")
                    setInputPassword({
                        password: "",
                        confirm_password: ""
                    })
                    setValidating("")
                } else {
                    setValidating(validating)
                }
            }
        } else {
            setValidating("All fields are required.")
        }



    }

    return (
        <div>
            <div className='flex h-[95vh] justify-center items-center '>
                <div className='bg-greenOnDarkMode text-white w-[30%] h-full
                 rounded-tl-2xl rounded-bl-2xl
                 flex justify-center items-center'>
                    <div className='p-4'>
                        <h3 className='font-bold text-xl'>Reset Password</h3>
                        <p className='text-sm'>Please enter a new password</p>

                        <div className='lg:w-[320px] mt-4 space-y-3'>
                            {/* CONFIRM NEW PASSWORD */}
                            <div className='relative'>
                                <PasswordResetInput
                                    name="password"
                                    value={inputPassword.password}
                                    handleChange={handleChange}
                                    type={`${showTypedPwd ? 'text' : 'password'}`}
                                    label="Password"
                                    placeholder=""
                                />
                                {showTypedPwd ?
                                    <IoMdEye className="absolute right-2 top-11 z-10
                                    text-2xl text-gray-700 cursor-pointer"
                                        onClick={togglePwd}
                                    />
                                    :
                                    <IoIosEyeOff
                                        className="absolute right-2 top-11 z-10
                                         text-2xl text-gray-700 cursor-pointer"
                                        onClick={togglePwd}
                                    />}
                            </div>

                            {/* CONFIRM NEW PASSWORD */}
                            <div className='relative'>
                                <PasswordResetInput
                                    name="confirm_password"
                                    value={inputPassword.confirm_password}
                                    handleChange={handleChange}
                                    type={`${showTypedPwd ? 'text' : 'password'}`}
                                    label="Confirm Password"
                                    placeholder=""
                                />
                                {showTypedPwd ?
                                    <IoMdEye className="absolute right-2 top-11 z-10
                                    text-2xl text-gray-700 cursor-pointer"
                                        onClick={togglePwd}
                                    />
                                    :
                                    <IoIosEyeOff
                                        className="absolute right-2 top-11 z-10
                                         text-2xl text-gray-700 cursor-pointer"
                                        onClick={togglePwd}
                                    />}
                            </div>



                            <PasswordFeedback
                                userFeedBack={state}
                            />
                            <p className='font-semibold text-[#C78686]'>{validating}</p>

                            <button onClick={resetPassword}
                                className='bg-updatedPurple mt-2 px-4 py-2.5
                             w-full rounded-lg hover:opacity-80'>
                                Reset Password
                            </button>



                        </div>
                    </div>
                </div>

                <div className='bg-white w-2/5 h-full  rounded-tr-2xl rounded-br-2xl'>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ResetPassword
