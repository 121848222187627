import React from 'react';


const TeamFeature = ({ src, desc, text, width, height }) => {
    return (
       <div className='p-6'>
         <div className=' flex flex-col flex-wrap justify-center items-center w-full m-4 space-y-0'>
            <div className={`width${width} height${height} bg-[#3C6E71] border-none  rounded-lg`}>
                <img src={src} alt='dev' className={`${height} ${width} border-none 
                                                     mx-auto rounded-lg -inset-2 left-2 bottom-2 shadow-lg relative  bg-[#ffffff]`} />
                                              
            </div>
            <p className=' text-xl text-center sm:text-2xl font-semibold md:font-bold my-1'>{desc}</p>
            <p className='text-sm md:text-xl text-center'>{text}</p>
        </div>
       </div>
    );
}

export {TeamFeature};
