import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    selectPolicyCardData,
    selectLoadingPage,
} from '../../redux/Slices/pagenationSlice';
import DashBotSearchBox from './DashMiscellaneous/DashBotSearchBox';
import DashPolicyCard from './DashMiscellaneous/DashPolicyCard';
import DashPagenation from './DashMiscellaneous/DashPagenation';
import { BsPlusLg } from 'react-icons/bs';
import { policyCardCategoryFilter, policySearchedLocation } from '../../redux/Slices/sharedUseEffectSlice';


const DashCardPanel = ({whatToDisplay}) => {
    const searched = useSelector(policySearchedLocation) //Input from filter by location in DashLocationSearchBox.js
    const filterArray = useSelector(policyCardCategoryFilter) //Array of checked under policy cards
    const [searchFeedBack, setSearchFeedBack] = useState("No Policy card for your Location.")

    const cards = useSelector(selectPolicyCardData)
    const [policyCardData, setPolicyCardData] = useState(cards)


    const loadingnumber = useSelector(selectLoadingPage);
    const maxPageNumber = cards.length > 0 && Math.ceil(cards.length / 3);
    const trendsTotal = [1, 2, 3, 4];
    const loadingStatus = useSelector(state => state.pagenation.loading)


    useEffect(() => {
        // FILTER ARRAY BY SEARCH TERM searched
        cards
        .filter(item => {
            if(searched === ""){
                return item
            }else{
                // Check if searched term is in Policy card location
                if(item && item?.location.toLowerCase().includes(searched.toLowerCase())){
                    return item
                }else{
                    return setSearchFeedBack("No Policy card for your searched Location.")
                }
            }


        })

        // FILTER BY POLICY TYPE
        const policyCategory = cards.length > 0 &&  cards.filter((item) => {
            if(filterArray.length > 0){
            if(filterArray.includes(item.category)){
                return item
            }else{
                return setSearchFeedBack("No Result...")
            }
        }else{
            return item
        }
        })

        setPolicyCardData(policyCategory)

         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadingStatus, filterArray, searched])

    return (
        <div className='pt-4'>
            <h1 className='hidden md:block mb-5 text-white font-nunito 
            md:text-xl lg:text-[36px] not-italic font-bold leading-normal'>
            {whatToDisplay === "policy-cards" ? 
            "Trending Policies"
             :
             "Debate Room"}
            </h1>
            {loadingStatus
            ?
            <div className='flex item-center justify-center h-full w-full'>
            <div
            className="my-4 animate-spin rounded-full h-16 w-16
            border-t-4 border-[#3C6E71]"></div>
            </div>
            :
            <div>
            {(policyCardData  && policyCardData.length > 0) ? policyCardData.filter((_, index) => (index >= loadingnumber * 3 - 3 && index <= loadingnumber * 3 - 1))
                .map((data, index) => (
                    <article key={index} className='mb-10'>

                        <div className='bg-nightModeDark py-3 flex justify-center flex-wrap md:flex-nowrap 
                        space-x-5 space-y-5 md:space-y-0 rounded-md'>
                            <section className='w-full md:w-[50%] '>
                            <DashPolicyCard data={data} />
                            </section>

                            {/* DATA ASIDE PANEL */}
                            <aside className='hidden md:block w-auto'>
                                <DashBotSearchBox />
                                <div className='flex flex-wrap w-full'>
                                    {trendsTotal.map((element, index) => (
                                        <div key={index} className='flex justify-center items-center
                    border-2 stretched-dash border-dashed  w-[45%] h-24 m-2.5 border-black'>
                                            <BsPlusLg className=' text-greenOnDarkMode
                                            font-bold text-5xl' />
                                        </div>)

                                    )}
                                </div>
                            </aside>
                        </div>
                    </article>
                ))
                :
                <div  className='flex justify-center py-6'>
                <p className='text-2xl items-center text-white font-bold'>{searchFeedBack}</p>
                </div>
                }
            {policyCardData && (policyCardData.length > 3 && <DashPagenation maxPageNumber={maxPageNumber} />)}
            </div>
        }
        </div>
    );
}

export default DashCardPanel;
