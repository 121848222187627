import React from 'react'
import { statsByLocation } from '../../../mockups/Dashboard'

function StatsBasics() {
  return (
    <div>
            <h3 className='text-xl font-semibold mt-3'>Current Users: 104,934</h3>
      

      <div className='my-2'>
      <p>Where are most users based?</p>
        {statsByLocation.map(({color, percentage, location}) => {
            return (
                <div key={color} className='my-2'>
                <p>{location}</p>
                <div 
                style={{ backgroundColor: color, width: percentage}}
                className='h-3 rounded-xl text-[10px] text-end pr-2'
                >{percentage}</div>
                </div>
            )
        })}
      </div>

      <div className='my-5'>
        <p className='font-semibold text-xl'>Most Searched Topics</p>
        <div className='text-sm'>
            <p>Labor Laws</p>
            <p>Taxes</p>
            <p>Oil Prices</p>
        </div>
      </div>
    </div>
  )
}

export default StatsBasics
