import React, { useEffect, useRef, useState } from 'react'
import { marketPlaceData } from '../../../mockups/Profile/profile_data'
import UserPsychometric from '../Psychometric/UserPsychometric'
import { transformData } from '../../common/transformNeo4j_to_Cytoscape'
import { testData } from '../Psychometric/dummyData'
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

function Analysis1() {

  const scrollContainerRef = useRef(null);
  const [psychometric, setPsychometric] = useState([])

  const getAnalysis = () => {
    const result = transformData(testData)
    setPsychometric(result)
  }

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.clientWidth / 2; // Scroll by half the container's width
      // If direction is right, scroll by half of the div width
      // else If direction is left, scroll by negative half of the div width
      container.scrollBy({
        left: direction === 'right' ? scrollAmount : -scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  
  
 

  useEffect(() => {  
    getAnalysis()
// eslint-disable-next-line 
  },[testData])

  return (
    <div className='sm:mr-4'>

            <div className='flex relative bg-nightModeDark h-[350px] my-4'>

           <div className='h-full flex items-center'>
           <IoMdArrowDropleft
            onClick={() => scroll('left')}
            className='cursor-pointer
                        text-3xl md:text-6xl text-white' /> 
           </div>

              <div 
              ref={scrollContainerRef}
              style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
              className='flex w-[97%] overflow-y-hidden 
              overflow-x-scroll space-x-8'>
                <UserPsychometric
                data={psychometric}
                 />
                    <UserPsychometric
                    data={psychometric}
                 />
              </div>

              <div className='h-full flex items-center'>
            <IoMdArrowDropright 
            onClick={() => scroll('right')}
            className='cursor-pointer
                        text-3xl md:text-6xl text-white' />  
               </div>


            </div>

            <div className='flex flex-wrap'>
            {marketPlaceData.slice(0,4).map(({head, child1, child2}, idx) => {
              return ( 
              <div key={idx} className='w-full sm:w-[43%] mx-2 my-4 sm:m-4 text-center'>
              <p className='bg-[#5B5B5B] rounded-md px-2 my-1'>{head}</p>
              <p>{child1}</p>
              <p>{child2}</p>
            </div>)
            })}
            </div>
    </div>
  )
}

export default Analysis1
