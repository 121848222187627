import React from 'react'

function DataSecurity() {
  return (
    <div className="w-full sm:w-[95%] lg:ml-4 py-3 bg-[#000000] rounded-l-md my-8">
    <div className='flex justify-center flex-wrap pt-4 px-6'>
    <h3 className='w-full sm:w-[83%] text-2xl lg:text-3xl font-semibold text-white'>
    Your Data is Safe With Us
    </h3>
    <p className='sm:w-[83%] py-4 text-sm sm:text-lg text-white'>
    Our adaptive AI system is backed by extensive research, enabling it to grow and get better at what it does. Even anonymously, our AI is able to analyze data and understand your needs better than any other systems out there. Thanks to your help, we continue to provide revolutionary tools and services
    for people around the world. Everyone’s voice deserves to be heard.</p>

    </div>
    </div>
  )
}

export default DataSecurity
