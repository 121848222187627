import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userInfo } from '../../../redux/Selectors/selectors'
import Componentsloader from '../../common/componentsloader'
import { Link } from 'react-router-dom'
import { query_or_manage_dataSet } from '../../../redux/Slices/sharedUseEffectSlice'

function ProfileDataSet() {
  const dispatch = useDispatch()
    const user = useSelector(userInfo)
    // eslint-disable-next-line 
    const userID = user && user.id
    const policyCards = [1, 2, 3]
    const isLoading = useSelector(state => state.pagenation.loading)


    return (
      <div className='mt-10 p-2 py-4 lg:py-2 relative my-5
      bg-nightModeDark lg:max-h-[800px] rounded-xl'>

      <p className='px-6 py-4 font-semibold text-2xl sticky'>Current Datasets</p>

      {isLoading ?
      <Componentsloader />
      :
      <>
      <div className='flex flex-wrap justify-center items-center h-[300px]
       overflow-y-scroll scroll-bar-in-dark p-2.5 lg:p-7 mb-[100px]'>
      {!!policyCards
      ? policyCards.map((item, idx) => {
          return (
              <Link
              to={``}
              key={idx}
              className='w-full my-4'>
              <div className='bg-white p-4 py-5 text-black rounded-md'>
              <div className='flex justify-between'>
                <p className='font-bold md:text-2xl'>Health Insurance</p>
                <p className='font-bold text-sm italic'>
                <span className='md:text-2xl not-italic text-[16px]'>$350 </span>
                 from 5/28 to 6/31</p>
              </div>
              <p className='mb-2 italic font-semibold text-xs md:text-[16px]'>Extracted from: 742 accounts</p>
              <div className='bg-[#353535] text-white p-2.5 md:p-4 text-justify rounded-md'>
                <p className='indent-3'>Based off your search, we recommend this dataset
                 for your purposes. Our PolicyWeb has carefully curated
                  this set of data through an accumulation of our most
                   frequented users. This ensures that both you and our
                    trusted partners are happy with the information we
                    are providing, Things you may see include, most relevant
                     discussion threads, data relevant to your needs, and graphs
                     that analyze the use of the website by our users.</p>
              </div>

              </div>

              </Link>
          )
      })
      :
      <p className='font-bold text-2xl'>No Data</p>

      }

      </div>


      </>
      }




      <div className='absolute bottom-0 p-2 pb-4 w-[95%] bg-nightModeDark
      flex justify-center'>
      <Link
    to="/profile/market-place/query"
    onClick={() => {
      dispatch(
        query_or_manage_dataSet({
        datasetQueryComponent : "manage"
      })
      )
    }}
    className='text-center bg-updatedPurple my-3 px-5 w-11/12
    py-2.5 rounded-lg hover:bg-[#767cb9]'>
    Click Here to View Past Purchases
    </Link>
      </div>
      </div>
    )
}

export default ProfileDataSet
