import React, { useEffect, useState } from 'react';
import DashHeader from "../components/Dashboard/DashHeader"
import DashMapSearchPanel from "../components/Dashboard/DashMapSearchPanel"
import DashCardPanel from "../components/Dashboard/DashCardPanel"
import { useDispatch, useSelector } from 'react-redux';
import { get_all_policy_card_API, get_all_policy_card_by_location_API } from '../redux/Thunks/policyCardThunk';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai"
import Footer from '../layout/Footer';
import { userInfo } from '../redux/Selectors/selectors';
import { policySearchedLocation, profile_completion, profile_survey } from '../redux/Slices/sharedUseEffectSlice';
import CompleteProfilePopUp from "../components/FeedBack/CompleteProfilePopUp";
import { useNavigate } from "react-router-dom";
import StatsMain from '../components/Dashboard/Statistics/StatsMain';
import DashboardCategory from '../components/Dashboard/DashboardCategory';
import DashDebateRoom from '../components/Dashboard/DebateRoom/DashDebateRoom';
import DashboardMobileScreenFilter from '../components/Dashboard/DashMiscellaneous/DashboardMobileScreenFilter';




const Dashboard = () => {
    const navigate = useNavigate()
    const popUp = useSelector(profile_survey)
    const dispatch = useDispatch()
    const [hideMap, setHideMap] = useState(false)
    const user = useSelector(userInfo)
    const location = useSelector(policySearchedLocation)
    const [whatToDisplay, setWhatToDisplay] = useState("policy-cards")


    const toggleMap = () => {
        setHideMap(!hideMap)
    }

    const closePopUp = () => {
        dispatch(profile_completion())
    }

    const handleDisplay = (displayType) => {
        setWhatToDisplay(displayType)
    }

    function getCardsFromServer(searchedParameter) {

        if (searchedParameter.length > 0) {
            // If user uses location search which means
            // they are interested in other locations
            // Get all policy cards by from database
            dispatch(
                get_all_policy_card_API({
                    clickedLoc: location
                })
            )
            // We will then filter later based on user location later

        } else {
            // Get all Policy cards by the current user location
            if (user && user?.state) {
                dispatch(
                    get_all_policy_card_by_location_API({
                        location: user && user.state
                    })
                )
            }
        }
    }

    useEffect(() => {

    }, [user, popUp])

    useEffect(() => {
        setTimeout(getCardsFromServer(location), 1500)
        // eslint-disable-next-line 
    }, [location, whatToDisplay])



    return (
        <div>
            <DashHeader />
            {/* SHOW IN MOBILE SCREEN */}
            <div className='block sm:hidden'> 
            <StatsMain />
            </div>

            <DashboardCategory 
            handleDisplay={handleDisplay}
            whatToDisplay={whatToDisplay}
             />


            <main className='bg-[#101010] lg:flex px-4 sm:px-7 mx-3 sm:mx-0 py-5 md:pt-10 lg:pt-20'>
                <DashboardMobileScreenFilter 
                handleClick={toggleMap}
                hideMap={hideMap}
                whatToDisplay={whatToDisplay}
                />

                <aside className='w-full lg:w-[35%] mr-20 mb-10'>
                    <DashMapSearchPanel hideMap={hideMap} />
                    {/* HIDE IN MOBILE SCREEN */}
                    <div className='hidden sm:block my-10'>
                    <StatsMain />
                    </div>
                </aside>

                <aside className='my-2 lg:my-0 w-full lg:w-[65%]'>
                    {whatToDisplay === "policy-cards"
                    ?
                    <DashCardPanel whatToDisplay={whatToDisplay} />
                    :
                    <DashDebateRoom whatToDisplay={whatToDisplay} />
                    }
                </aside>


                {/* Profile completion popup */}
                {popUp && (user && (user?.state === "" || user?.email === "" || user?.city === ""))
                    ?
                    <CompleteProfilePopUp
                        handleClosePopUp={closePopUp}
                        onClick={() => {
                            closePopUp()
                            navigate("/settings")
                        }}
                    />
                    :
                    null
                }
                
            </main>


            <Footer />
        </div>
    );
};

export default Dashboard;
