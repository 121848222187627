import {
    createAsyncThunk
} from "@reduxjs/toolkit";
import {
    makeApiRequest
} from "../../components/common/API_request";
import { headers, headerConfiguration } from "../../store/auth/headerConfig";

export const post_comment = createAsyncThunk(
    "comments_slice/post_comment",
    async (payload, {getState}) => {
        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${payload.eid}/comments/`
            const body = {
                post: payload.eid,
                author: payload.user_id,
                text: payload.comment,
                parent: payload.parent_comment_id
            }
            return await makeApiRequest(url, "POST", headerConfig, body);

        } catch (error) {
            // console.error("Error Posting Comment.", error);
            throw error; // Rethrow the error to handle it in the Redux reducer
        }
    }
)


export const fetch_all_comments = createAsyncThunk(
    "comments_slice/fetch_all_comments",
    async (payload, {getState}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${payload.eid}/comments/`

            return await makeApiRequest(url, "GET", headerConfig, null);
        } catch (error) {
            // console.error("Error fetching comments:", error);
            throw error; // Rethrow the error to handle it in the Redux reducer
        }
    }
)

// FETCH COMMENTS BY ID
export const fetch_comments_by_user = createAsyncThunk(
    "comments_slice/fetch_comments_by_user",
    async (payload, {getState}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/comments/${payload.user_id}/`

            return await makeApiRequest(url, "GET", headerConfig, null);
        } catch (error) {
            // console.error("Error fetching comments:", error);
            throw error; // Rethrow the error to handle it in the Redux reducer
        }
    }
)

// DELETE COMMENTS API
export const delete_comment_by_id = createAsyncThunk(
    "comments_slice/delete_comment_by_id",
    async (payload, {getState}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/comments/${payload.eid}/delete-patch/`
            const response = await fetch(url, {
                method: "DELETE",
                headers: headerConfig
            })

            if (response.ok || response.status === 204) {
                return payload.eid
            } else {
                // Handle error cases here, you can throw an error or return an error object
                const errorData = await response.json();
                throw new Error(errorData.message); // For example, assuming the API returns an error message in the response
            }
        } catch (error) {
            // console.error("Error fetching transcripts:", error);
            throw error; // Rethrow the error to handle it in the Redux reducer
        }
    }
)


// UPDATE COMMENTS API
export const update_comment_by_id = createAsyncThunk(
    "comments_slice/update_comment_by_id",
    async ({eid, edited_comment}, {getState}) => {

        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/comments/${eid}/delete-patch/`
            const body = { text: edited_comment}
            return await makeApiRequest(url, "PATCH", headerConfig, body);
        } catch (error) {
            // console.error("Comment update error", error);
            throw error; // Rethrow the error to handle it in the Redux reducer
        }
    }
)


// UPVOTE OR DOWNVOTE
export const reaction_activity = createAsyncThunk(
    'comments_slice/reaction_activity',
    async ({eid,user_id,content_type,vote_type}, {getState}) => {
        
        const headerConfig = headerConfiguration(getState, headers)
        try{
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${user_id}/reaction/${eid}/`
            const body = {
                voter: user_id,
                content_type,
                vote_type,
                object_id:eid,
            }
            const response = await makeApiRequest(url, "POST", headerConfig, body)

            return response;
        }catch(err){
            throw err
        }
    }
)


// UPVOTE OR DOWNVOTE
export const get_reaction_activity_by_user = createAsyncThunk(
    'comments_slice/get_reaction_activity_by_user',
    async ({eid,user_id}, {getState}) => {
        const headerConfig = headerConfiguration(getState, headers)
        try{
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${user_id}/reaction/${eid}/`
            const response = await makeApiRequest(url, "GET", headerConfig)
            return response;
        }catch(err){
            throw err
        }
    }
)
