import { toast } from "react-toastify";

export const uploadImageToCloudinary = async (file, cloudName, uploadPreset) => {
 toast.loading("Processing")
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
  
    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, {
        method: 'POST',
        body: formData
      });
  
      if (!response.ok) {
        toast.dismiss()
        toast.error("Internal server Error")
        // throw new Error('Upload failed');
      }
  
      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      toast.dismiss()
      toast.error("Network Error")
    //   console.error('Error uploading image:', error);
    //   throw error;
    }
  };