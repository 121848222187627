export const policyDummyData = [
    {
        "location":"Alaska",
        "eid": "c0acdc37-bb19-47dc-b76c-5281c0fb8d9d",
        "date_created": "2024-04-06T17:58:02.495363Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Encourage participation in community service and volunteer programs to promote a sense of unity and address social issues. This will not only help address specific problems within the community, but also foster a sense of belonging and cooperation among individuals from different backgrounds. Additionally, provide resources and support for community-led initiatives and grassroots organizations that work towards addressing social issues. This will empower individuals to take ownership of the issues they care about and create meaningful change within their own communities.",
        "userneed_id": 7,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"Alaska",
        "eid": "5a3945f6-7051-4468-a859-262ca97a5e2a",
        "date_created": "2024-04-06T17:58:02.479701Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Recommendation: As a helpful assistant, I would suggest investing in improving and maintaining infrastructure as a top priority for the government. This would involve updating and repairing existing roads, bridges, and buildings, as well as implementing new infrastructure projects to meet the growing needs of the population. Not only would this create jobs and stimulate the economy, but it would also improve the overall safety and efficiency of transportation and communication systems. Additionally, investing in green and sustainable infrastructure would help reduce our carbon footprint and support the transition to a more environmentally friendly society.",
        "userneed_id": 6,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"Texas",
        "eid": "182b0f79-bd4c-4bdc-acb5-8ce57158bbad",
        "date_created": "2024-04-06T17:58:02.407093Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Implementing comprehensive education programs on empathy and diversity to promote understanding and tolerance among individuals in society. This can help reduce discrimination and prejudice, ultimately creating a more harmonious and inclusive community. Additionally, providing resources and support for marginalized groups can help address systemic issues and create a more equal and just society for all individuals.",
        "userneed_id": 5,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"Alabama",
        "eid": "ca2e77d2-1aac-4e83-a426-e6873d664a48",
        "date_created": "2024-04-06T17:58:02.307848Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: My recommendation is for governments and organizations to prioritize investing in and improving infrastructure. This includes building and maintaining roads, bridges, public transportation systems, and other essential systems that support the economy and quality of life for citizens. By making these improvements, we can reduce traffic congestion, improve access to goods and services, and create jobs. Additionally, investing in sustainable and resilient infrastructure can help mitigate the effects of climate change and promote a more environmentally-friendly society. This policy would not only address a current need, but also have long-term benefits for the community.",
        "userneed_id": 4,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"Ohio",
        "eid": "d714d470-1518-45b9-abef-527ca56687da",
        "date_created": "2024-04-06T17:58:02.288809Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy recommendation: Implement a national infrastructure plan that focuses on modernizing and repairing aging roads, bridges, and public transportation systems. This will not only improve the safety and efficiency of our transportation networks, but also create jobs and stimulate economic growth. Additionally, invest in renewable energy infrastructure to reduce our reliance on fossil fuels and combat climate change.",
        "userneed_id": 3,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"Ohio",
        "eid": "c498a060-4a9a-4bc9-998e-561388b45bca",
        "date_created": "2024-04-06T17:58:02.214579Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Recommendation: As technology continues to advance and shape our society, it is important for governments and politicians to stay informed and up-to-date on the latest developments. I recommend implementing mandatory technology training for all government officials and politicians. This will ensure that they have a strong understanding of how technology works and how it impacts our daily lives, allowing them to make more informed decisions and policies. Additionally, creating a dedicated department or committee focused on technology and its impact on society can help bridge the gap between government and the tech industry, leading to more effective and efficient policies in this rapidly evolving landscape.",
        "userneed_id": 2,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"WASHINGTON DC",
        "eid": "7bf19f9f-c04e-4b29-8d67-fe4a9b31e190",
        "date_created": "2024-04-06T17:58:02.188765Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Implement mandatory diversity training and cultural sensitivity programs in schools and workplaces to address systemic discrimination and promote inclusivity. This will help create a more equitable society and reduce negative social impacts caused by prejudice and bias. Additionally, provide resources and support for marginalized communities to empower them and address any existing inequalities.",
        "userneed_id": 1,
        "category": "Urban Development",
        "effective_date": "2024-04-06T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "713e2fa0-9b2f-4fa9-bfd3-27607320858b",
        "survey_id": "9d50ac15-4e3d-483e-8f21-ff5736e86a36"
    },
    {
        "location":"WASHINGTON DC",
        "eid": "9884a8f2-e23e-493a-bf2c-16dcdec77f30",
        "date_created": "2024-04-05T10:14:41.987579Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Recommendation: In order to promote social justice and equity, it is essential to address systemic inequalities and provide equal opportunities for all individuals. This can be achieved through policies such as implementing affirmative action programs, increasing funding for education and training programs for marginalized communities, and promoting diversity and inclusion in all sectors. Additionally, it is important to address issues such as poverty, housing insecurity, and healthcare disparities in order to create a more equitable society. It may also be beneficial to create policies that promote equal pay and fair labor practices, as well as implementing measures to combat discrimination and promote diversity in the workforce. Ultimately, a comprehensive approach that addresses both systemic and individual barriers is crucial in achieving social justice and equity for all.",
        "userneed_id": 4,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Kansas",
        "eid": "e87d1451-9b74-4474-98c1-e2736872db1e",
        "date_created": "2024-04-05T10:14:41.978515Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Implement a mandatory diversity and inclusion training program for all businesses and organizations to promote equality and address systemic discrimination. This program should cover topics such as unconscious bias, cultural competency, and inclusive language, and should be regularly updated to reflect current social issues. Additionally, businesses and organizations should be required to report on their progress and efforts towards promoting diversity and inclusion, and those who fail to comply should face penalties. This policy will help create a more inclusive and equal society, where all individuals have equal opportunities and are treated with respect and dignity.",
        "userneed_id": 3,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Kansas",
        "eid": "164cb14d-c36b-48e3-87dc-bb6a26fd0fad",
        "date_created": "2024-04-05T10:14:41.968790Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Implement a comprehensive emergency response plan that prioritizes the safety and well-being of all individuals, particularly those who are most vulnerable and marginalized. This plan should include provisions for adequate resources, support systems, and communication channels to effectively address any crisis situation. Additionally, there should be a focus on promoting social justice and equity in the response efforts to ensure that no one is left behind or disproportionately impacted by the crisis. This could include measures such as providing equal access to healthcare, distributing resources based on need, and actively addressing systemic inequalities. Regular evaluations and updates to the emergency response plan should also be conducted to ensure its effectiveness and inclusivity.",
        "userneed_id": 2,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Kansas",
        "eid": "4bfeeace-683a-493c-a961-f33eec64583f",
        "date_created": "2024-04-05T10:14:41.946323Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Recommendation: It would be beneficial for you to further specify your need and determine which specific area it relates to in order to receive more targeted and effective solutions. Additionally, seeking support from a trusted friend, family member, or professional could also be helpful in addressing your need.",
        "userneed_id": 1,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Illinois",
        "eid": "0a370ab0-597f-4344-b7bf-7d0f1bbeecd5",
        "date_created": "2024-04-05T10:14:14.420162Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Recommendation: As a society, it is important to recognize and address systemic discrimination and inequality. This can be achieved through implementing laws and policies that protect the rights of marginalized groups, promoting diversity and inclusion in all aspects of society, and educating individuals on the importance of equality and acceptance. Additionally, promoting and supporting organizations and initiatives that promote civil rights can also help create a more equitable and just society for all individuals.",
        "userneed_id": 4,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Los Angeles",
        "eid": "89c07349-b5e7-43b3-b9ca-13ae077f99d1",
        "date_created": "2024-04-05T10:14:14.412827Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Implement and enforce strict anti-discrimination laws to promote equal opportunities for all individuals regardless of their race, gender, sexual orientation, religion, or socio-economic status. Additionally, invest in education and training programs to reduce the achievement gap and promote diversity and inclusivity in the workplace and educational institutions. Encourage public and private sector organizations to prioritize diversity and inclusion in their hiring practices and policies. Provide resources and support for marginalized communities to access affordable housing, healthcare, and other basic needs. Regularly review and update policies to ensure they are effectively promoting social justice and equity for all individuals.",
        "userneed_id": 3,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Miami",
        "eid": "b64b8f79-a7c7-4834-ab09-0379694cd4be",
        "date_created": "2024-04-05T10:14:14.404528Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Policy Recommendation: Implement a comprehensive crisis response plan that prioritizes the well-being and safety of all individuals, especially the most vulnerable members of society. This plan should include measures such as providing essential resources (food, shelter, medical care) to those in need, creating safe spaces for individuals to seek help and support, and ensuring equal access to information and resources for all members of society regardless of their economic or social status. Additionally, the plan should address any systemic issues that may worsen the impact of a crisis on certain marginalized groups, and work towards long-term solutions to prevent future crises.",
        "userneed_id": 2,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    },
    {
        "location":"Miami",
        "eid": "d9a282ce-1cb0-4dd7-bba8-9dbf35678931",
        "date_created": "2024-04-05T10:14:14.393830Z",
        "upvote_count": 0,
        "downvote_count": 0,
        "content": "Possible recommendation: Conduct further research and clarification on the specific need to determine the best course of action. This could involve gathering data, consulting experts, and conducting surveys or studies to better understand the issue and develop targeted solutions. It may also be helpful to involve stakeholders and individuals affected by the need in the decision-making process to ensure their voices and perspectives are heard. Additionally, exploring potential partnerships or collaborations with organizations or groups that have expertise in addressing similar needs could be beneficial.",
        "userneed_id": 1,
        "category": "Urban Development",
        "effective_date": "2024-04-05T00:00:00Z",
        "policy_makers": "N/A",
        "voting_status": false,
        "regional_info": "N/A",
        "transcript_id": "0d0ed07f-1019-4da9-b52a-f09c4716c567",
        "survey_id": "cd28e091-71fe-4ec8-9e4a-2c239011e874"
    }
]

export const testingComments =[
    {
        "eid": "c367cfac-a5d7-4544-ad38-2246240ba624",
        "date_created": "2024-03-14T19:49:13.778942Z",
        "upvote_count":10,
        "downvote_count": 10,
        "text": "Hello",
        "deleted": false,
        "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
        "author": 2,
        "parent": null
    },
    {
        "eid": "c367cfac-a5d7-4544-ad38-2246240ba624",
        "date_created": "2024-03-14T19:49:13.778942Z",
        "upvote_count": 20,
        "downvote_count": 20,
        "text": "Hello",
        "deleted": false,
        "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
        "author": 2,
        "parent": null
    },
    {
        "eid": "c367cfac-a5d7-4544-ad38-2246240ba624",
        "date_created": "2024-03-14T19:49:13.778942Z",
        "upvote_count": 30,
        "downvote_count": 30,
        "text": "Hello",
        "deleted": false,
        "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
        "author": 2,
        "parent": null
    },
    {
        "eid": "c367cfac-a5d7-4544-ad38-2246240ba624",
        "date_created": "2024-03-14T19:49:13.778942Z",
        "upvote_count": 40,
        "downvote_count": 40,
        "text": "Hello",
        "deleted": false,
        "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
        "author": 2,
        "parent": null
    },
    {
        "eid": "0d512f66-9f29-4258-858e-0141e18b363f",
        "date_created": "2024-03-14T19:52:22.554967Z",
        "upvote_count": 2,
        "downvote_count": 3,
        "text": "Yes",
        "deleted": false,
        "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
        "author": 2,
        "parent": "c367cfac-a5d7-4544-ad38-2246240ba624"
    },
        {
            "eid": "c367cfac-a5d7-4544-ad38-2246240ba624",
            "date_created": "2024-03-14T19:49:13.778942Z",
            "upvote_count": 0,
            "downvote_count": 0,
            "text": "Hello",
            "deleted": false,
            "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
            "author": 2,
            "parent": null
        },
        {
            "eid": "0d512f66-9f29-4258-858e-0141e18b363f",
            "date_created": "2024-03-14T19:52:22.554967Z",
            "upvote_count": 0,
            "downvote_count": 0,
            "text": "Yes",
            "deleted": false,
            "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
            "author": 2,
            "parent": "c367cfac-a5d7-4544-ad38-2246240ba624"
        },
        {
            "eid": "831974ec-9393-4da4-ae38-9982cd4591d4",
            "date_created": "2024-03-14T19:52:29.349678Z",
            "upvote_count": 20,
            "downvote_count": 10,
            "text": "Npoer",
            "deleted": false,
            "post": "1df096de-cf6c-4d4c-bafd-0a353a588c22",
            "author": 2,
            "parent": "c367cfac-a5d7-4544-ad38-2246240ba624"
        }

]


export const statsByLocation = [
    {
        color:"#C40C0C",
        percentage:"95%",
        location:"Ohio"
    },
    {
        color:"#FF6500",
        percentage:"69%",
        location:"New York"
    },
    {
        color:"#5F5D9C",
        percentage:"54%",
        location:"Alabama"
    },
    {
        color:"#003C43",
        percentage:"30%",
        location:"Texas"
    }, 
    {
        color:"#D20062",
        percentage:"9%",
        location:"Alaska"
    },
]