import React, { useEffect, useState } from 'react'
import UserProfile from '../components/Profile/UserProfile'
import Footer from '../layout/Footer'
import ProfileSettings from '../components/Settings/ProfileSettings'
import Payments from '../components/Settings/Payments'
import AddNewPaymentMethod from '../components/Settings/AddNewPaymentMethod'
import { useSelector } from 'react-redux'
import { userInfo } from '../redux/Selectors/selectors'
import { useLocation } from 'react-router-dom'

function Settings() {
  const user = useSelector(userInfo)
  const location = useLocation()
  const [isLocationSettings] = useState(location && location.pathname !== "/settings")


  const [addNewPayment, setAddNewPayment]  = useState(false)

  const changeScreen = () => {
    setAddNewPayment(!addNewPayment)
  }

  useEffect(() => {
    // eslint-disable-next-line 
  },[isLocationSettings, user])


  return (
    <div>
    <div className='bg-greenOnDarkMode text-white p-4 mt-[100px]'>
    <UserProfile />

    <div className='flex justify-center flex-wrap px-4'>
        <ProfileSettings />
    </div>

    <div className='block lg:hidden '>
    {addNewPayment ?
    <AddNewPaymentMethod
    addNewPaymentBox={changeScreen}
     />
    :
    <Payments
    addNewPaymentBox={changeScreen}
     />
    }
    </div>


    </div>
    <Footer />
    </div>
  )
}

export default Settings
