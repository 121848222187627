import React from 'react'

function PasswordFeedback({ userFeedBack }) {
    if(userFeedBack > 0){
        return (
        <>
            <div className='w-full flex flex-wrap space-x-2'>
                {
                    (userFeedBack === 1) ?
                    <>
                        <p className='h-[5px] w-14 bg-[#C78686]'> </p>
                    </> :
                    (userFeedBack === 2) ?
                    <>
                        <p className='h-[5px] w-14 bg-[#C78686]'> </p>
                        <p className='h-[5px] w-12 bg-[#9F846B]'></p>
                    </> :
                    (userFeedBack === 3) ?
                    <>
                        <p className='h-[5px] w-14 bg-[#C78686]'> </p>
                        <p className='h-[5px] w-12 bg-[#9F846B]'></p>
                        <p className='h-[5px] w-12 bg-[#9B9F6B]'></p>
                        <p className='h-[5px] w-14 bg-[#729F6B]'></p>
                    </>
                    :
                    ""
                }
            </div>

            <p className='flex w-full text-sm justify-end pb-3'>
                {
                    (userFeedBack === 1) ?
                        "Too Weak" :
                        (userFeedBack === 2) ?
                            "Weak" :
                            (userFeedBack === 3) ?
                                "Very Strong" :
                                ""
                }
            </p>
        </>
    )
    }
}

export default PasswordFeedback
