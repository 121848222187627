import React, { useEffect } from 'react'
import DataCard from '../common/DataCard'
import { useSelector } from 'react-redux';
import { datasetCard } from '../../redux/Slices/datasetSlice';
import PurchaseHistory from './PastPurchases/PurchaseHistory';

function Purchased() {

  const purchasedHistory = useSelector(datasetCard)


  const handleRenewal = () => {

  }

  useEffect(() => {

  }, [purchasedHistory])

  return (
    <div className='px-2.5 py-5 sm:p-5'>
      <p className='text-2xl md:text-4xl text-center pb-4'>Current Datasets</p>

      <div className='px-3 lg:px-10'>
        <DataCard />
      </div>
      {(purchasedHistory && purchasedHistory.length > 0)
         &&
          <PurchaseHistory 
            pastData={purchasedHistory}
            handleRenewal={handleRenewal}
          />
         }
    </div>
  )
}

export default Purchased
