import React, { useState, useEffect } from 'react';
import CommentImpression from './commentImpression';
import { Replies, ShowReplies } from './Replies';
import ChatPrompt from '../../ChatBox/ChatPrompt';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { delete_comment_by_id, get_reaction_activity_by_user, post_comment, reaction_activity, update_comment_by_id } from '../../../redux/Thunks/commentsThunk';
import { isCommentDeleted, reactionsbyID, sortCommentFunction } from '../../../redux/Selectors/selectors';
import { readableDate } from '../../common/set_date';
import { arrayInputOnChange, changeArrayItemState, resetActions } from '../../common/show_and_hide';
import ChatPopUp from '../../ChatBox/ChatPopUp';
import { userInfo } from '../../../redux/Selectors/selectors';
import { getNewCommentsTotal, selectRenderedState, setSharedState, sortingCategory } from '../../../redux/Slices/sharedUseEffectSlice';

// import { testingComments } from '../../../mockups/Dashboard';

const CommentSection = () => {
    let userID;
    const user = useSelector(state => userInfo(state))
    if (user) {
        userID = user.id;
    }

    const { policyCardID } = useParams(); // Access the parameter value
    const dispatch = useDispatch()

    const toRefresh = useSelector(state => selectRenderedState(state))
    const isLoading = useSelector(state => state.all_comments.loading)
    const reactionsPerComment = useSelector(reactionsbyID)



    const [popUp, setPopUp] = useState(false)  //PopUp Hidden initially

    const sortedBy = useSelector(sortingCategory)
    const unFilteredCommentsTotal = useSelector(isCommentDeleted) //testingComments
    const commentsTotal = sortCommentFunction(sortedBy, unFilteredCommentsTotal)





    // console.log(sortedBy)

    // Filter all comments to only show those that the parent field is null. These will
    // be the comments to each policy card
    // and not the reply(comments with parent that is not  null) to each comments
    const comments_with_null_parent = commentsTotal.filter(({ parent }) => {
        return parent === null
    })



    const [editAction, setEditAction] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));
    const [showHideReplies, setShowHideReplies] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));
    const [hideBox, setHideBox] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));
    const [moreAction, setMoreAction] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));

    const [comment, setComment] = useState("")
    const [reply, setReply] = useState("")
    const [commentID, setCommentID] = useState("")
    let [inputValue, setInputValue] = useState(
        Array.from({ length: comments_with_null_parent.length }, () => "")
    );


    
    // // This function aims to set every state in the comments page
    function resetEveryState() {
        
    //     const result = hideBox.map((item) => {
    //         if(item){
    //             item = false
    //         }
    //         return item
    //     })

        

    //     // const initialState = Array.from(
    //     //     { length: comments_with_null_parent.length },
    //     //     () => false
    //     // )
    //     // setHideBox(result)
    }


    // SHOW AND HIDE REPLIES
    const handleClick = (index) => {
        changeArrayItemState(index, showHideReplies, setShowHideReplies)
    }

    // SHOW AND HIDE REPLY INPUT
    const toggle_reply_box = (index) => {
        resetActions(index, comments_with_null_parent, setHideBox)
        setMoreAction(Array.from(
            { length: comments_with_null_parent.length },
            () => false
        ))
    }

    // SHOW AND HIDE EDIT/DELETE CONTAINER
    const show_more_actions = (index) => {
        changeArrayItemState(index, moreAction, setMoreAction)
    }

    //ON COMMENT CHANGE
    const inputOnChange = (index, e) => {
        arrayInputOnChange(index, e, inputValue, setInputValue)
    }

    // POST COMMENT
    const submitComment = (e) => {
        toggle_reply_box()
        e.preventDefault()
        if (comment) {
            dispatch(
                post_comment({
                    eid: policyCardID,
                    user_id: userID, //user_id will be fetched from user details
                    comment,
                    parent_comment_id: null
                    //Since it is a comment to the policy card,
                    // it does not have a parent
                })

            )
            dispatch(
                setSharedState(!toRefresh) //Refresh Page
            )
        }

        setComment("")
    }

    // POST REPLY
    const submitReply = (id) => {
        toggle_reply_box()
        if (reply) {
            dispatch(
                post_comment({
                    eid: policyCardID,
                    user_id: userID,
                    comment: reply,
                    parent_comment_id: id
                    //parent_comment_id is set to the id of the comment it is replied to.
                })
            )
            dispatch(
                setSharedState(!toRefresh) //Refresh Page
            )
        }

        setReply("")
    }

    // SHOW THE EDIT INPUT BOX
    const handleEdit = (comment_id, index) => {
        setInputValue(
            comments_with_null_parent.map(({ text }) => {
                return text;
            })
        )
        resetActions(index, comments_with_null_parent, setEditAction)
        setCommentID(comment_id)
        show_more_actions(index)
    }

    // UPDATE COMMENT
    const confirmUpdate = (comment_id, index) => {
        handleEdit()
        show_more_actions()
        toggle_reply_box()

        if (!inputValue[index]) {
            alert("This field cannot be empty!")
        } else {
            dispatch(
                update_comment_by_id({
                    eid: comment_id,
                    edited_comment: inputValue[index]
                })
            )
        }



    }
    const handleDelete = () => {
        toggle_reply_box()
        if (commentID) {
            dispatch(
                delete_comment_by_id({
                    eid: commentID
                })
            )
        }
        setPopUp(!popUp)

    }


    const reactionActivity = (e, user_id, comment_id) => {

        if (e.target.id === "Thumbs_Up") {
            dispatch(
                reaction_activity({
                    eid: comment_id,
                    user_id,
                    content_type: "comment",
                    vote_type: 'U'
                })
            )
        } else if (e.target.id === "Thumbs_Down") {
            dispatch(
                reaction_activity({
                    eid: comment_id,
                    user_id,
                    content_type: "comment",
                    vote_type: 'D'
                })
            )
        }

        // GET REACTION ON THIS COMMENT
        dispatch(
            get_reaction_activity_by_user({
                user_id,
                eid: comment_id
            })
        )


    }




    useEffect(() => {
        if (comments_with_null_parent) {
            dispatch(
                getNewCommentsTotal({
                    currentNumberOfComments: comments_with_null_parent.length
                })
            )
        }
        sortCommentFunction(sortedBy, unFilteredCommentsTotal)



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedBy, policyCardID])

    return (
        <div onClick={resetEveryState}>
            {isLoading
                ?
                <div className='flex item-center justify-center h-full w-full'>
                    <div
                        className="my-4 animate-spin rounded-full h-16 w-16
            border-t-4 border-[#3C6E71]"></div>
                </div>
                :
                <div>
                    {comments_with_null_parent &&

                        comments_with_null_parent.map(({ eid, date_created, upvote_count,
                            downvote_count, text, post, author, username, deleted, profileImage }, index) => {



                            const date_object = readableDate(date_created)
                            // Filter all comments to only show those that the parent field is not null.
                            // These will be the reply to each comments
                            const replies_per_comment = commentsTotal.filter(({ parent }) => {
                                return String(parent) === String(eid)
                            })

                            const matched = reactionsPerComment.filter(({ object_id }) => {
                                return eid === object_id
                            })





                            return (
                                <div key={index} data-id={eid}>
                                    <CommentImpression
                                        id={eid}
                                        commentPoster={userID}
                                        author_ID={author}
                                        index={index}
                                        username={username}
                                        img={profileImage}
                                        comment_date={date_object}
                                        comment_text={text}
                                        upvote_count={upvote_count}
                                        downvote_count={downvote_count}
                                        userHasReacted={matched}
                                        replyComment={submitReply}
                                        reply={reply}
                                        handleReplyChange={e => setReply(e.target.value)}
                                        replyPromptShow={hideBox}
                                        edit_delete_div={moreAction}
                                        edit_box={editAction}
                                        input_value={inputValue[index]}
                                        set_Input_Value={(e) => inputOnChange(index, e)}
                                        toggleReplyBox={() => {
                                            toggle_reply_box(index)
                                            changeArrayItemState(index, hideBox, setHideBox)
                                        }}
                                        showMoreActions={show_more_actions}
                                        cancelAction={() => {
                                            handleEdit()
                                            show_more_actions()
                                            toggle_reply_box()
                                        }}
                                        deleteComment={(comment_id) => {
                                            setCommentID(comment_id)
                                            setPopUp(!popUp)
                                        }}
                                        handleUpdate={confirmUpdate}
                                        editComment={handleEdit}
                                        setInputValue={e => setCommentID(e.target.value)}
                                        reaction={(e) => {
                                            dispatch(
                                                setSharedState(!toRefresh) //Refresh Page
                                            )
                                            reactionActivity(e, userID, eid)
                                        }}
                                    />

                                    <ShowReplies
                                        index={index}
                                        arrayToMap={replies_per_comment}
                                        seeReplies={handleClick}
                                        setArray={showHideReplies}
                                    />

                                    <Replies
                                        showHideReplies={showHideReplies}
                                        index={index}
                                        arrayToMap={replies_per_comment}
                                        userHasReacted={matched}
                                        reaction={(e, innerCommentID) => {
                                            dispatch(
                                                setSharedState(!toRefresh)
                                            )
                                            reactionActivity(e, userID, innerCommentID)
                                        }}

                                    />

                                    {/* <hr /> */}
                                </div>
                            )
                        })
                    }
                </div>

                /* Close of comments section below */
            }
            <ChatPrompt
                currentMode={true}
                bgOnDark="bg-nightModeDark"
                position="static"
                placeholder="Post your comment here..."
                submitPrompt={submitComment}
                value={comment}
                handleChange={(e) => {
                    setComment(e.target.value)
                }} />


            {/* POP UP */}
            {popUp && <ChatPopUp
                objectModel="Comment?"
                handleClosePopUp={() => setPopUp(!popUp)}
                handleCancel={() => setPopUp(!popUp)}
                promptTarget="This comment"
                handleDelete={handleDelete}

            />}




        </div>

    );
}

export default CommentSection;
