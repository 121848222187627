import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { briefDesc } from "../../mockups/Home/promotionData";

const Promotion = () => {
  const [inViewIndex, setInViewIndex] = useState(null);


  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation
      once: true, // Animation only happens once
    });

    const handleScroll = () => {
      const sections = document.querySelectorAll('.promotion-section');
      let index = -1;
      sections.forEach((section, i) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          index = i;
        }
      });
      setInViewIndex(index);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  return (
    <div className="lg:hidden w-full sm:w-[95%] lg:ml-4 py-3 bg-backgroundColor rounded-l-md my-8 ">
      <div>
        <h1 className="px-6 font-nunito italic font-bold sm:text-2xl md:text-3xl mb-4 text-white">
          Our services allow you to...
        </h1>

        {briefDesc.map((section, index) => {
          return (
            <div
              key={index}
              className={`flex flex-wrap my-7 h-[190px] promotion-section
             ${index === inViewIndex && "bg-greenOnDarkMode"}
             ${index === 1 ? 'justify-end ml-4 w-full py-4' : 'justify-start w-[95%] p-4 pl-6'}
             `}

            >
              <h1 className="w-3/4 text-2xl md:text-3xl lg:text-5xl font-semibold mr-2 text-white">{section.title}</h1>
              <p className="w-3/5 italic font-bold mt-2.5 sm:mt-6 sm:text-lg text-white">
                {section.text}
              </p>
            </div>)
        }

        )}
      </div>
    </div>
  );
};

export default Promotion;
