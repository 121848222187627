import React from 'react'
import { Link } from 'react-router-dom'
import Bot from '../../common/bot'
import { useSelector } from 'react-redux'
import { selectTranscripts } from '../../../redux/Selectors/selectors'
import Componentsloader from '../../common/componentsloader'

function PolicyWeb() {
  const isLoading = useSelector(state => state.botChats.loading)
  const transcripts = useSelector(selectTranscripts)

  return (
    <div className='px-6'>
              <p className='hidden lg:block font-semibold text-2xl'>PolicyWeb</p>
      {isLoading ?
      <Componentsloader />
      :
      <>

      {transcripts && <p className="my-5 text-xl font-semibold">Chat Log
        <span className='text-sm'> (Last 30 days)</span></p>}

      <div className='text-sm'>
        {transcripts.length === 0 
        ?
        <p className='text-center text-xl font-semibold py-4'>No Chats data.</p>
        :
          <>
          {transcripts
         ? transcripts.toReversed().slice(0,3).map(({transcript_id, title}, idx) => {
          return (
            <div
            key={idx}
            className='flex justify-between lg:pr-3 my-4'
            >
            <p className='w-3/5 py-0.5'>{title}</p>

            <div className='flex items-center'>
            <Link to={`/chat/${transcript_id}`}
            className='bg-updatedPurple px-2 lg:px-5 py-0.5
                                        hover:bg-[#767cb9]'>
            Go to Chat
            </Link>
            </div>
            </div>
          )
        })
        :
        <p className='text-center text-xl font-semibold py-4'>No Chats data.</p>
        }
          </>
        }
      </div>
      </>
      }

      {/* {
                    (userFeedBack === 1) ?
                        "Too Weak" :
                        (userFeedBack === 2) ?
                            "Weak" :
                            (userFeedBack === 3) ?
                                "Very Strong" :
                                ""
                } */}

      <Bot url="/chat" />
    </div>
  )
}

export default PolicyWeb
